import React from "react";
import { GrPrevious } from "react-icons/gr";
import { GrNext } from "react-icons/gr";
const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const pageNumbers = [];

  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }
  const handleLinkClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <nav className="mb-4 mt-4">
      <ul className="pagination ">
        <li className="page-item ">
          <button
            disabled={currentPage === 1}
            className="page-link h-8 hover:disabled:cursor-not-allowed"
            onClick={() => onPageChange(currentPage - 1)}
          >
            <GrPrevious style={{color:"#006776"}} />
          </button>
        </li>
        {pageNumbers.map((number) => (
          <li
            key={number}
            className={`page-item ${currentPage === number ? "active" : ""}`}
          >
            <button
              className="page-link h-8 items-center flex"
              style={{background:"#006776", color:"white"}}
              onClick={() => onPageChange(number)}
            >
              {number}
            </button>
          </li>
        ))}
        <li className="page-item">
          <button
            disabled={currentPage === totalPages}
            className="page-link h-8 hover:disabled:cursor-not-allowed"
            onClick={() => onPageChange(currentPage + 1)}
          >
            <GrNext style={{color:"#006776"}}/>
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
