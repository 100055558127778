import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import "./Career.css";
import SEO from "../SEO/SEO";

const Career = () => {
  const [flag, setFlag] = useState(false);
  const [career, setData] = useState([]);

  useEffect(() => {
    const fuun = async () => {
      setFlag(true);
      let { data } = await axios.get(
        "https://service-spotrack-dubphwg5cae3f4da.centralindia-01.azurewebsites.net/api/v1/career//get_all_career?company_id=9B1F21C0-D3EC-46E2-9554-58148EB1BFDF&search&page_no=1&rows_per_page=20"
      );

      data = data.responseObj.result;
      setData(data);
      setFlag(false);
    };
    fuun();
  }, []);
  // console.log(ShowModal,"sdhh")

  const handleLinkClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const navigate = useNavigate();

  const handleCardClick = (job) => {
    navigate(`/career-details/${job.carrer_id}`);
  };

  return (
    <div>
    <SEO canonical={"https://satellitekeyit.com/career"}/>
      {/*--------middle-----start-------*/}
      {/*counter--start*/}
      <div className="counter-main">
        <div className="about-men-bg careers-us-main">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div
                  className="about-men"
                  data-aos="flip-left"
                  data-aos-duration={500}
                >
                  <h2>CAREERS</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="about-bottom-men">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="about-bottom">
                  <h6>
                    <label>
                      <Link to={"/"} onClick={handleLinkClick}>
                        Home
                      </Link>
                    </label>
                    / Careers
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* counter---end*/}
      {/*about-us--start */}
      <div className="about-us-main">
        <div className="container">
          <div className="row">
            {flag ? (
              <div style={{ textAlign: "center" }}>
                <h4>Loading...</h4>
              </div>
            ) : (
              career.map((job, index) => (
                <div className="col-md-6 col-lg-4 mb-4">
                  <div className="blog-men-text pb-2 ">
                    <div className="row blog-text">
                      <div className="col-lg-12">
                        <div className="blog-name blog-name-1">
                          <h6 className="jobTitle">{job?.job_title}</h6>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="blog-name">
                          <h6>{job.job_type}</h6>
                          <h6>Experience :- {job.job_requirement}</h6>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="blog-name float-end">
                          <h6>Location: {job.location}</h6>
                        </div>
                      </div>
                      <div class="col-lg-12 pt-2">
                        <div class="blog-text3">
                          <h6>Job Description:</h6>
                          <p className="jobReq-ax"> {job.job_description}</p>
                        </div>
                      </div>

                      <div className="col-lg-12 pt-2">
                        <div className="blog-text3">
                          {/* Button trigger modal */}
                          <button
                            type="button"
                            className="apply-now-btn"
                            onClick={() => {
                              // setIsOpen({
                              //   state: true,
                              //   title: job?.job_title,
                              // });
                              handleCardClick(job);
                              handleLinkClick();
                            }}
                          >
                            View And Apply
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>

      {/*about-us-end*/}
      {/*----middle-----end--------------------*/}
    </div>
  );
};

export default Career;
