import React from "react";
import { Link } from "react-router-dom";

const Portfolio = () => {
  const handleLinkClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <div>
      {/*--------middle-----start-------*/}
      {/*counter--start*/}
      <div className="counter-main">
        <div className="about-men-bg portfolio-us-main">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div
                  className="about-men"
                  data-aos="flip-left"
                  data-aos-duration={500}
                >
                  <h2>PORTFOLIO</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="about-bottom-men">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="about-bottom">
                  <h6>
                    <label>
                      <Link to={"/"} onClick={handleLinkClick}>
                        Home{" "}
                      </Link>
                    </label>
                    / Portfolio
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* counter---end*/}
      {/*about-us--start */}
      <div className="about-us-main">
        <div className="container">
          <div className="row">
            <div
              className="col-md-6 col-lg-4 mb-3"
              data-aos="zoom-in"
              data-aos-duration={1000}
            >
              <div className="portfolio-box">
                <img
                  className="img-fluid"
                  src={require('../../assets/images/portfolio1.png')}
                  alt="Spotrack Portfolio Image"
                />
              </div>
              <div className="portfolio-text">
                <p>
                  <b>Spotrack</b> -&nbsp;Explore All Best Gps Technology
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-4 mb-3"
              data-aos="zoom-in"
              data-aos-duration={1000}
            >
              <div className="portfolio-box">
                <img
                  className="img-fluid"
                  src={require('../../assets/images/portfolio2.png')}
                  alt="Washwoosh Portfolio Image"
                />
              </div>
              <div className="portfolio-text">
                <p>
                  <b>Washwoosh</b> -&nbsp;Explore All Best Maintenance Anywhere
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-4 mb-3"
              data-aos="zoom-in"
              data-aos-duration={1000}
            >
              <div className="portfolio-box">
                <img
                  className="img-fluid"
                  src={require('../../assets/images/portfolio3.png')}
                  alt="Fashion web site Image"
                />
              </div>
              <div className="portfolio-text">
                <p>
                  <b>khomefashion</b> -&nbsp;Explore All Best Khome Fashion
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-4 mb-3"
              data-aos="zoom-in"
              data-aos-duration={1000}
            >
              <div className="portfolio-box">
                <img
                  className="img-fluid"
                  src={require('../../assets/images/portfolio4.png')}
                  alt="Skymmd Portfolio Image"
                />
              </div>
              <div className="portfolio-text">
                <p>
                  <b>Skymmd</b> -&nbsp;Explore All Best Hotel Booking{" "}
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-4 mb-3"
              data-aos="zoom-in"
              data-aos-duration={1000}
            >
              <div className="portfolio-box">
                <img
                  className="img-fluid"
                  src={require('../../assets/images/portfolio5.png')}
                  alt="Hrms Portfolio Image"
                />
              </div>
              <div className="portfolio-text">
                <p>
                  <b>Hrms</b> -&nbsp;Explore All Best Hr Management Software
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-4 mb-3"
              data-aos="zoom-in"
              data-aos-duration={1000}
            >
              <div className="portfolio-box">
                <img
                  className="img-fluid"
                  src={require('../../assets/images/portfolio6.png')}
                  alt="Washwoosh Admin panel Portfolio Image"
                />
              </div>
              <div className="portfolio-text">
                <p>
                  <b>Washwoosh</b> -&nbsp;Explore All Best Admin panel
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-4 mb-3"
              data-aos="zoom-in"
              data-aos-duration={1000}
            >
              <div className="portfolio-box">
                <img
                  className="img-fluid"
                  src={require('../../assets/images/portfolio1-ww-app.png')}
                  alt="washwoosh Portfolio"
                />
              </div>
              <div className="portfolio-text">
                <p>
                  <b>Washwoosh</b> -&nbsp;Explore All Best Maintenance Anywhere
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-4 mb-3"
              data-aos="zoom-in"
              data-aos-duration={1000}
            >
              <div className="portfolio-box">
                <img
                  className="img-fluid"
                  src={require('../../assets/images/portfolio1-hrms-app.png')}
                  alt="Human Resourse Management System Portfolio Image"
                />
              </div>
              <div className="portfolio-text">
                <p>
                  <b>Hrms</b> -&nbsp;Explore All Best Hr Management App
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-4 mb-3"
              data-aos="zoom-in"
              data-aos-duration={1000}
            >
              <div className="portfolio-box">
                <img
                  className="img-fluid"
                  src={require('../../assets/images/portfolio1-skymmd-app.png')}
                  alt="Skymmd- Best Hotel Booking app Portfolio Image"
                />
              </div>
              <div className="portfolio-text">
                <p>
                  <b>Skymmd</b> -&nbsp;Explore All Best Hotel Booking App
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-4 mb-3"
              data-aos="zoom-in"
              data-aos-duration={1000}
            >
              <div className="portfolio-box">
                <img
                  className="img-fluid"
                  src={require('../../assets/images/portfolio7.png')}
                  alt="best logo designing portfolio Image"
                />
              </div>
              <div className="portfolio-text">
                <p>
                  <b>Logos</b> -&nbsp;Explore All Best logos
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-4 mb-3"
              data-aos="zoom-in"
              data-aos-duration={1000}
            >
              <div className="portfolio-box">
                <img
                  className="img-fluid"
                  src={require('../../assets/images/portfolio8.png')}
                  alt="Best Logos Portfolio Image"
                />
              </div>
              <div className="portfolio-text">
                <p>
                  <b>Logos</b> -&nbsp;Explore All Best logos
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*about-us-end*/}
      {/*----middle-----end--------------------*/}
    </div>
  );
};

export default Portfolio;
