import axios from 'axios';
import React, { useRef, useState } from 'react'
import toast from 'react-hot-toast';
import validator from 'validator';

 // ApplyModal Start
 const ApplyModal = ({title , isOpen , closeModal}) => {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [number, setNumber] = useState("");
    const [file, setFile] = useState(null);
    const inputFile = useRef(null);
    const [send, setSend] = useState("Send Message");
  
    const clearData = () => {
      setEmail("");
      setName("");
      setNumber("");
      setFile("");
      setFile(null);
      handleReset();
      closeModal();
    };
  
    const clearError = () => {
      setError({
        name: "",
        email: "",
        number: "",
        file: "",
      });
    };
  
    const [error, setError] = useState({
      name: "",
      email: "",
      number: "",
      file: "",
    });
  
    const handleSubmit = async (event) => {
      try {
        event.preventDefault();
  
        let tempError = {};
        if (!name) {
          setError((prev) => ({ ...prev, name: "Name Is Required" }));
          tempError.name = "Name Is Required";
        } else if (
          !validator.isAlpha(name.replace(/\s+/g, ""), "en-US", { ignore: " " })
        ) {
          setError((prev) => ({
            ...prev,
            name: "Name should only contain letters or spaces",
          }));
          tempError.name = "Name should only contain letters or spaces";
        } else {
          setError((prev) => ({ ...prev, name: "" }));
          tempError.name = "";
        }
        const regExForContact =
          /^\s*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
  
        if (!number) {
          setError((prev) => ({ ...prev, number: "Number Is Required" }));
          tempError.number = "Number Is Required";
        } else if (!regExForContact.test(number)) {
          setError((prev) => ({ ...prev, number: "Invalid Mobile Number" }));
          tempError.number = "Invalid Mobile Number";
        } else {
          setError((prev) => ({ ...prev, number: "" }));
          tempError.number = "";
        }
  
        if (!email) {
          setError((prev) => ({ ...prev, email: "Email Is Required" }));
          tempError.email = "Email Is Required";
        } else if (!validator.isEmail(email)) {
          setError((prev) => ({ ...prev, email: "Invalid Email" }));
          tempError.email = "Invalid Email";
        } else {
          setError((prev) => ({ ...prev, email: "" }));
          tempError.email = "";
        }
  
        if (!file) {
          setError((prev) => ({ ...prev, file: "File Is Required" }));
          tempError.file = "File is Required";
        } else {
          setError((prev) => ({ ...prev, file: "" }));
          tempError.file = "";
        }
  
        if (
          tempError.email ||
          tempError.name ||
          tempError.number ||
          tempError.file
        ) {
          // setLoading("")
          return;
        }
  
        setSend("Sending...");
  
        const formData = new FormData();
        formData.append("name", name);
        formData.append("contact", number);
        formData.append("email", email);
        formData.append("company_id", "9B1F21C0-D3EC-46E2-9554-58148EB1BFDF");
        formData.append("resume", file[0]);
        formData.append("job_title", title);
  
        // return
  
        const res = await axios.post(
          "https://service-spotrack-dubphwg5cae3f4da.centralindia-01.azurewebsites.net/api/v1/mail/addCarrierInquiry-satelitekey",
          formData
        );
  
        let to = res.data.result.result;
        toast.success(to);
        setSend("Send Message");
        clearData();
        clearError();
      } catch (error) {
        toast.error("Something Went Wrong");
        clearData();
        setSend("Send Message");
      }
    };
    const handleReset = () => {
      if (inputFile.current) {
        inputFile.current.value = "";
        inputFile.current.type = "text";
        inputFile.current.type = "file";
      }
      // setError(prev =>{...prev, file: ""})
      setError((prev) => ({ ...prev, name: "" }));
    };
  
    return (
      <div>
        {/* Apply Now Modal */}
        {isOpen.state && (
          <div
            className="modal fade show d-block"
            // id="exampleModal-one"
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.151)",
            }}
            tabIndex={-1}
            data-bs-backdrop="static"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Apply for {title}
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={() => {
                      clearData();
                      clearError();
                    }}
                    // data-bs-dismiss="modal"
                    aria-label="Close"
                  />
                </div>
                <div className="modal-body">
                  <div className="contact-men-form">
                    <form>
                      <input
                        className="input-btn"
                        type="text"
                        placeholder="Your name *"
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                          setError((prev) => ({
                            ...prev,
                            name: "",
                          }));
                        }}
                      />
                      <div className="error-aa">{error.name}</div>
                      <input
                        className="input-btn"
                        type="text"
                        value={email}
                        placeholder="Your Email *"
                        onChange={(e) => {
                          setEmail(e.target.value);
                          setError((prev) => ({
                            ...prev,
                            email: "",
                          }));
                        }}
                      />
                      <div className="error-aa">{error.email}</div>
                      <input
                        className="input-btn"
                        type="text"
                        placeholder="Your Contact No.*"
                        onChange={(e) => {
                          setNumber(e.target.value);
                          setError((prev) => ({
                            ...prev,
                            number: "",
                          }));
                        }}
                        value={number}
                      />
                      <div className="error-aa">{error.number}</div>
                      <div className="col-lg-12">
                        <input
                          className="input-btn"
                          type="file"
                          accept=".pdf, .doc, .docx"
                          onChange={(e) => {
                            setFile(e.target.files);
                            setError((prev) => ({
                              ...prev,
                              file: "",
                            }));
                          }}
                          required
                          ref={inputFile}
                        />
                        <div className="ssssaaaa">
                          <div className="error-aa">{error.file}</div>
                          <label htmlFor="formFile2" className="form-resume">
                            Attach Resume (.pdf, .doc, .docx)
                          </label>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="apply-now-btn"
                    onClick={(e) => {
                      handleSubmit(e);
                    }}
                  >
                    {send}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

export default ApplyModal
