import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import "./Blog.css";
import Pagination from "../Pagination/Pagination";

const Blog = () => {
  const [Loading, setLoading] = useState(false);
  const [blogData, setBlogData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const itemsPerPage = 9;
  useEffect(() => {
    const apiData = {
      itemPerPage: itemsPerPage,
      currentPage: currentPage,
    };

    const GetBlogApi = async (apiData) => {
      const data = await axios.get(
        `https://service-spotrack-dubphwg5cae3f4da.centralindia-01.azurewebsites.net/api/v1/blog/get_all_blog?company_id=9B1F21C0-D3EC-46E2-9554-58148EB1BFDF&search=&page_no=${apiData.currentPage}&rows_per_page=${apiData.itemPerPage}`
      );

      return data;
    };

    GetBlogApi(apiData)
      .then((res) => {
        setLoading(true);
        console.log(res.data.responseObj.result);
        setBlogData(res.data.responseObj.result);
        setCount(res.data.responseObj.count);
        setTimeout(() => {
          setLoading(false);
        }, 100);
      })
      .catch((error) => {
        console.log("Error fetching blogs", error);
        toast("Something went wrong meaning");
      });
  }, [currentPage]);

  const totalPages = Math.ceil(count / itemsPerPage);

  const navigate = useNavigate();
  const handleCardClick = (data) => {
    navigate(`/blogDetails/${data}`);
  };

  const handleLinkClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  return (
    <div>
      <div>
        {/*--------middle-----start-------*/}
        {/*counter--start*/}
        <div className="counter-main">
          <div className="about-men-bg blog-us-main">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div
                    className="about-men"
                    data-aos="flip-left"
                    data-aos-duration={500}
                  >
                    <h2>BLOG</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="about-bottom-men">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="about-bottom">
                    <h6>
                      <label>
                        <Link to={"/"} onClick={handleLinkClick}>
                          Home{" "}
                        </Link>
                      </label>
                      / Blog
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* counter---end*/}
        {/*about-us--start */}
        <div className="about-us-main">
          <div className="container">
            <div className="row">
              {Loading ? (
                <div style={{ textAlign: "center" }}>
                  <h2>Loading...</h2>
                </div>
              ) : (
                blogData?.map((item, index) => {
                  return (
                    <div
                      className="col-md-6 col-lg-4 mb-4"
                      data-aos="zoom-in"
                      data-aos-duration={1000}
                      key={index}
                    >
                      <div className="blog-men-text">
                        <img
                          className="img-fluid blogImg-a"
                          src={`${item.blog_image}`}
                          alt="IT Solutions Blog Image"
                        />
                        <div className="row blog-text">
                          <div className="col-lg-6">
                            <div className="blog-name">
                              <h6>
                                <i className="fa-regular fa-user" />
                                &nbsp;By {item?.blog_author}
                              </h6>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="blog-name float-end">
                              <h6>
                                <i className="fa-solid fa-calendar-days" />
                                &nbsp;{formatDate(item.created_on)}
                              </h6>
                            </div>
                          </div>
                          <div className="col-lg-12 pt-2">
                            <div className="blog-text1">
                              <h6 className="blogTitle-a">{item.blog_title}</h6>
                              <p className="blogContent-a">{item.content}</p>

                              <strong
                              id="readMore-a"
                                onClick={() =>{ handleCardClick(item.blog_id); handleLinkClick()}}
                              >
                                read more...
                              </strong>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </div>
        {/*about-us-end*/}
        <div className="pagination-a">
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={setCurrentPage}
            
          />
        </div>
        {/*----middle-----end--------------------*/}
      </div>
    </div>
  );
};

export default Blog;
