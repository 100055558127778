import React from "react";
import { Helmet } from "react-helmet-async";

const SEO = ({
  title,
  description,
  name,
  type,
  keywords,
  twTitle,
  twDescription,
  ogTitle,
  ogDescription,
  twName,
  ogType,
  twType,
  scriptData,
  canonical,
}) => {
  return (
    <div>
      <Helmet>
        {/* Standard metadata tags */}
        {title && <title>{title}</title>}
        {description && <meta name="description" content={description} />}
        {keywords && <meta name="keywords" content={keywords} />}
        {/* End standard metadata tags */}

        {/* Facebook tags */}
        {type && <meta property="og:type" content={ogType} />}
        {title && <meta property="og:title" content={ogTitle} />}
        {description && (
          <meta property="og:description" content={ogDescription} />
        )}
        {/* End Facebook tags */}

        {/* Twitter tags */}
        {name && <meta name="twitter:creator" content={twName} />}
        {type && <meta name="twitter:card" content={twType} />}
        {title && <meta name="twitter:title" content={twTitle} />}
        {description && (
          <meta name="twitter:description" content={twDescription} />
        )}
        {/* End Twitter tags */}

        {scriptData && 
        <script type="application/ld+json">
        {JSON.stringify(scriptData)}
      </script>
        }

        {canonical && 
        <link rel="canonical" href={`${canonical}`} />
        }

      </Helmet>
    </div>
  );
};

export default SEO;
