import React, { useState } from "react";
import { Link } from "react-router-dom";
import sampleUiImg from '../../assets/images/sample-ui.png'

const Services = () => {
  const handleLinkClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const [isActiveTab, setIsActiveTab] = useState("tab-1");

  const changeTab = (tab) => {
    setIsActiveTab(tab);
  };
  return (
    <div>
      {/*--------middle-----start-------*/}
      {/*counter--start*/}
      <div className="counter-main">
        <div className="about-men-bg services-men-bg">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div
                  className="about-men"
                  data-aos="flip-left"
                  data-aos-duration={500}
                >
                  <h2>SERVICES</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="about-bottom-men">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="about-bottom">
                  <h6>
                    <label>
                      <Link to={"/"} onClick={handleLinkClick}>
                        Home{" "}
                      </Link>
                    </label>
                    / Services
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* counter---end*/}
      <div className="tab-class text-center profile-men services-main services-bg ">
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-lg-3">
              <div
                className="services-menu-left"
                data-aos="zoom-in"
                data-aos-duration={1000}
              >
              <h2 style={{color:"#006776", fontSize:"1.5rem", marginTop:"20px"}}>Our Services</h2>
                <ul
                  className="nav nav-pills d-inline-flex justify-content-center mb-3 wow bounceInUp mt-2"
                  data-wow-delay="0.1s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.1s",
                    animationName: "bounceInUp",
                  }}
                  role="tablist"
                >
                  <li className="nav-item navs" role="presentation">
                    <a
                      className="py-2 mx-2 tab-bt bg-white active"
                      data-bs-toggle="pill"
                      href="#tab-1"
                      aria-selected="false"
                      role="tab"
                      onClick={() => changeTab("tab-1")}
                      tabIndex={-1}
                    >
                      UI/UX Design
                    </a>
                  </li>
                  <li className="nav-item navs" role="presentation">
                    <a
                      className="py-2 mx-2 tab-bt bg-white "
                      data-bs-toggle="pill"
                      href="#tab-2"
                      aria-selected="false"
                      role="tab"
                      onClick={() => changeTab("tab-2")}
                      tabIndex={-1}
                    >
                      App Development &amp; Support
                    </a>
                  </li>
                  <li className="nav-item navs" role="presentation">
                    <a
                      className="py-2 mx-2 tab-bt bg-white"
                      data-bs-toggle="pill"
                      href="#tab-3"
                      aria-selected="false"
                      role="tab"
                      onClick={() => changeTab("tab-3")}
                      tabIndex={-1}
                    >
                      It consulting strategy
                    </a>
                  </li>
                  <li className="nav-item navs" role="presentation">
                    <a
                      className="py-2 mx-2 tab-bt bg-white"
                      data-bs-toggle="pill"
                      href="#tab-4"
                      aria-selected="false"
                      role="tab"
                      onClick={() => changeTab("tab-4")}
                      tabIndex={-1}
                    >
                      Custom software development
                    </a>
                  </li>
                  <li className="nav-item navs" role="presentation">
                    <a
                      className="py-2 mx-2 bg-white tab-bt"
                      data-bs-toggle="pill"
                      href="#tab-5"
                      aria-selected="true"
                      role="tab"
                      onClick={() => changeTab("tab-5")}
                    >
                      Cloud computing
                    </a>
                  </li>
                  <li className="nav-item navs" role="presentation">
                    <a
                      className="py-2 mx-2 bg-white tab-bt"
                      data-bs-toggle="pill"
                      href="#tab-6"
                      aria-selected="true"
                      role="tab"
                      onClick={() => changeTab("tab-6")}
                    >
                      digital marketing services
                    </a>
                  </li>
                </ul>
                <div className="tab-content">
                  <div
                    id="tab-1"
                    className={`tab-pane fade p-0 sample ${
                      isActiveTab === "tab-1" ? "active show" : ""
                    }`}
                    role="tabpanel"
                  >
                    <div className="sample-main">
                      <h4>SAMPLE LIST</h4>
                      <img
                        className="img-fluid"
                        src={sampleUiImg}
                        alt
                      />
                      <h6>
                        <a href="#">www.spotrack.in</a>
                      </h6>
                    </div>
                    <div className="sample-main">
                      <img
                        className="img-fluid"
                        src={sampleUiImg}
                        alt
                      />
                      <h6>
                        <a href="#">www.spotrack.in</a>
                      </h6>
                    </div>
                  </div>
                  <div
                    id="tab-2"
                    className={`tab-pane fade p-0 sample ${
                      isActiveTab === "tab-2" ? "active show" : ""
                    }`}
                    role="tabpanel"
                  >
                    <div className="sample-main">
                      <h4>SAMPLE LIST</h4>
                      <img
                        className="img-fluid"
                        src={sampleUiImg}
                        alt
                      />
                      <h6>
                        <a href="#">www.spotrack.in</a>
                      </h6>
                    </div>
                    <div className="sample-main">
                      <img
                        className="img-fluid"
                        src={sampleUiImg}
                        alt
                      />
                      <h6>
                        <a href="#">www.spotrack.in</a>
                      </h6>
                    </div>
                  </div>
                  <div
                    id="tab-3"
                    className={`tab-pane fade p-0 sample ${
                      isActiveTab === "tab-3" ? "active show" : ""
                    }`}
                    role="tabpanel"
                  >
                    <div className="sample-main">
                      <h4>SAMPLE LIST</h4>
                      <img
                        className="img-fluid"
                        src={sampleUiImg}
                        alt
                      />
                      <h6>
                        <a href="#">www.spotrack.in</a>
                      </h6>
                    </div>
                    <div className="sample-main">
                      <img
                        className="img-fluid"
                        src={sampleUiImg}
                        alt
                      />
                      <h6>
                        <a href="#">www.spotrack.in</a>
                      </h6>
                    </div>
                  </div>
                  <div
                    id="tab-4"
                    className={`tab-pane fade p-0 sample ${
                      isActiveTab === "tab-4" ? "active show" : ""
                    }`}
                    role="tabpanel"
                  >
                    <div className="sample-main">
                      <h4>SAMPLE LIST</h4>
                      <img
                        className="img-fluid"
                        src={sampleUiImg}
                        alt
                      />
                      <h6>
                        <a href="#">www.spotrack.in</a>
                      </h6>
                    </div>
                    <div className="sample-main">
                      <img
                        className="img-fluid"
                        src={sampleUiImg}
                        alt
                      />
                      <h6>
                        <a href="#">www.spotrack.in</a>
                      </h6>
                    </div>
                  </div>
                  <div
                    id="tab-5"
                    className={`tab-pane fade p-0 sample ${
                      isActiveTab === "tab-5" ? "active show" : ""
                    }`}
                    role="tabpanel"
                  >
                    <div className="sample-main">
                      <h4>SAMPLE LIST</h4>
                      <img
                        className="img-fluid"
                        src={sampleUiImg}
                        alt
                      />
                      <h6>
                        <a href="#">www.spotrack.in</a>
                      </h6>
                    </div>
                    <div className="sample-main">
                      <img
                        className="img-fluid"
                        src={sampleUiImg}
                        alt
                      />
                      <h6>
                        <a href="#">www.spotrack.in</a>
                      </h6>
                    </div>
                  </div>
                  <div
                    id="tab-6"
                    className={`tab-pane fade p-0 sample ${
                      isActiveTab === "tab-6" ? "active show" : ""
                    }`}
                    role="tabpanel"
                  >
                    <div className="sample-main">
                      <h4>SAMPLE LIST</h4>
                      <img
                        className="img-fluid"
                        src={sampleUiImg}
                        alt
                      />
                      <h6>
                        <a href="#">www.spotrack.in</a>
                      </h6>
                    </div>
                    <div className="sample-main">
                      <img
                        className="img-fluid"
                        src={sampleUiImg}
                        alt
                      />
                      <h6>
                        <a href="#">www.spotrack.in</a>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-8 col-lg-9">
              <div
                className="profile-right"
                data-aos="zoom-in"
                data-aos-duration={1000}
              >
                <div className="tab-content">
                  <div
                    id="tab-1"
                    className={`tab-pane fade p-0 sample ${
                      isActiveTab === "tab-1" ? "active show" : ""
                    }`}
                    role="tabpanel"
                  >
                    <div className="row">
                      <div
                        className="col-lg-12 wow bounceInUp"
                        style={{ visibility: "visible" }}
                      >
                        <div className="ux-ui-main">
                          <img
                            className="img-fluid"
                            src={require('../../assets/images/uiux-banner.png')}
                          />
                          <h3 className="pt-3">uI/UX DESIGN</h3>
                          <p>
                            At Satellitekey It solution pvt.ltd, we believe that
                            outstanding user interfaces and experiences are the
                            cornerstone of successful digital products. Our
                            UI/UX design services are dedicated to creating
                            visually stunning and highly functional designs that
                            engage users and drive results. Whether you’re
                            launching a new app or refining an existing one, our
                            team is here to elevate your product with
                            thoughtful, user-centered design.
                          </p>
                          <h2 style={{color:"#006776", fontSize:"1.5rem"}}>Why Choose SateliteKey IT?</h2>
                          <div className="services-text1">
                            <h6>1. User-Centric Approach</h6>
                            <ul>
                              <li>
                                Great design starts with understanding the user.
                                We prioritize user research and usability
                                testing to ensure our designs address the needs
                                and preferences of your target audience. Our
                                goal is to create intuitive, accessible, and
                                enjoyable experiences that keep users coming
                                back.
                              </li>
                            </ul>
                          </div>
                          <div className="services-text1">
                            <h6>2. Holistic Design Process</h6>
                            <p>
                              Our design process encompasses every aspect of
                              UI/UX:
                            </p>
                            <ul>
                              <li>
                                <strong>Research &amp; Discovery:</strong>We
                                conduct thorough research to gain insights into
                                user behavior, market trends, and business
                                objectives.
                              </li>
                              <li>
                                <strong>Wireframing &amp; Prototyping:</strong>
                                We develop wireframes and prototypes to
                                visualize the layout, functionality, and flow of
                                your application.
                              </li>
                              <li>
                                <strong>Visual Design:</strong> Our designers
                                craft aesthetically pleasing and
                                brand-consistent interfaces that captivate users
                                and enhance usability.
                              </li>
                              <li>
                                <strong>User Testing &amp; Feedback </strong>We
                                test our designs with real users to gather
                                feedback and make data-driven improvements.{" "}
                              </li>
                              <li>
                                <strong>Implementation &amp; Support: </strong>
                                We work closely with developers to ensure a
                                seamless transition from design to development
                                and provide ongoing support for design updates
                                and refinements.{" "}
                              </li>
                            </ul>
                          </div>
                          <div className="services-text1">
                            <h6>3. Responsive &amp; Adaptive Designs</h6>
                            <ul>
                              <li>
                                In a world where users access applications
                                across various devices and screen sizes, our
                                designs are optimized for responsiveness and
                                adaptability. We ensure your product looks and
                                functions beautifully whether on a smartphone,
                                tablet, or desktop.
                              </li>
                            </ul>
                          </div>
                          <div className="services-text1">
                            <h6>4. Brand Consistency</h6>
                            <ul>
                              <li>
                                Your brand identity is crucial to your success.
                                Our designs reflect your brand’s personality and
                                values, creating a cohesive and memorable user
                                experience that strengthens brand recognition
                                and trust.
                              </li>
                            </ul>
                          </div>
                          <div className="services-text1">
                            <h6>5. Innovation &amp; Trends</h6>
                            <p>
                            We stay ahead of design trends and technological advancements to bring you the latest in design innovation. Our designs incorporate modern aesthetics and functionalities to keep your product relevant and engaging.
                            </p>
                            <ul>
                              <li>
                                <strong>Mobile UI/UX Design:</strong>Designing
                                intuitive and visually appealing interfaces for
                                iOS and Android applications.
                              </li>
                              <li>
                                <strong>Wireframing &amp; Prototyping:</strong>
                                We develop wireframes and prototypes to
                                visualize the layout, functionality, and flow of
                                your application.
                              </li>
                              <li>
                                <strong>Web UI/UX Design: </strong> Creating
                                engaging and functional designs for websites and
                                web applications.
                              </li>
                              <li>
                                <strong>E-commerce Design:</strong>ECrafting
                                user-friendly designs that enhance the online
                                shopping experience and drive conversions.
                              </li>
                              <li>
                                <strong>Enterprise UI/UX Design: </strong>{" "}
                                Developing complex interfaces for enterprise
                                solutions that streamline workflows and improve
                                productivity.{" "}
                              </li>
                              <li>
                                <strong>Prototyping &amp; Wireframing:</strong>{" "}
                                Building interactive prototypes and wireframes
                                to visualize and test design concepts early in
                                the process.{" "}
                              </li>
                            </ul>
                          </div>
                          <div className="services-text1">
                            <h6> Let’s Create Something Amazing Together</h6>

                            <p>
                              Ready to transform your digital product with
                              exceptional UI/UX design? Contact us today to
                              discuss your project and discover how our design
                              services can enhance your user experience and
                              drive success. At Satellitekey It solution
                              pvt.ltd, we’re passionate about creating designs
                              that make an impact and exceed expectations.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="tab-2"
                    className={`tab-pane fade p-0 sample ${
                      isActiveTab === "tab-2" ? "active show" : ""
                    }`}
                    role="tabpanel"
                  >
                    <div className="row">
                      <div
                        className="col-lg-12 wow bounceInUp"
                        style={{ visibility: "visible" }}
                      >
                        <div className="ux-ui-main">
                          <img
                            className="img-fluid"
                            src={require('../../assets/images/app-banner.png')}
                          />
                          <h3 className="pt-3">
                            App Development &amp; Support
                          </h3>
                          <p>
                            At Satellitekey It solution pvt.ltd. , we specialize
                            in creating custom mobile and online applications
                            that carry your business forward. Our devoted team
                            of developers, designers, and strategists
                            collaborate to bring your vision to life, making
                            your app stand out in a competitive market. We
                            understand that every business is unique. That’s why
                            we offer customized app development services
                            tailored to meet your specific needs. From concept
                            to deployment, we work closely with you to design
                            and develop applications that align with your goals
                            and objectives.
                          </p>
                          <h2 style={{color:"#006776", fontSize:"1.5rem"}}>Why Choose SateliteKey IT?</h2>
                          <div className="services-text1">
                            <h6>1. End-to-End Development</h6>
                            <p>
                              Our comprehensive approach covers every aspect of
                              app development:
                            </p>
                            <ul>
                              <li>
                                <strong>Consultation &amp; Strategy:</strong> We
                                begin by understanding your business, audience,
                                and goals to create a strategic plan.
                              </li>
                              <li>
                                <strong>Design &amp; Prototyping: </strong> Our
                                designers create intuitive, user-centric
                                interfaces and prototypes to ensure the best
                                user experience.
                              </li>
                              <li>
                                <strong>Development:</strong> Using the latest
                                technologies and frameworks, our developers
                                build robust, scalable, and high-performance
                                applications.
                              </li>
                              <li>
                                <strong>Testing &amp; QA:</strong> Rigorous
                                testing ensures your app functions flawlessly
                                across all devices and platforms.{" "}
                              </li>
                              <li>
                                <strong>Launch &amp; Support: </strong> We
                                assist with app deployment and offer ongoing
                                support to address any issues and keep your app
                                running smoothly.{" "}
                              </li>
                            </ul>
                          </div>
                          <div className="services-text1">
                            <h6>2 . Cross-Platform Expertise</h6>
                            <ul>
                              <li>
                                Whether you need a native app for iOS or
                                Android, or a cross-platform solution, our team
                                is skilled in various development environments.
                                We use cutting-edge technologies to deliver
                                high-quality apps that perform seamlessly across
                                multiple platforms.
                              </li>
                            </ul>
                          </div>
                          <div className="services-text1">
                            <h6>3. User-Centric Approach</h6>
                            <ul>
                              <li>
                                We leverage the latest advancements in
                                technology to create apps that are not only
                                functional but also innovative. From AI and
                                machine learning to augmented reality and IoT,
                                our solutions incorporate modern features that
                                enhance user engagement and deliver exceptional
                                experiences.{" "}
                              </li>
                            </ul>
                          </div>
                          <div className="services-text1">
                            <h6>4. User-Centric Design</h6>
                            <ul>
                              <li>
                                A great app starts with a great design. Our
                                design philosophy focuses on creating intuitive
                                and visually appealing interfaces that provide a
                                seamless user experience. We ensure that your
                                app is not only attractive but also easy to
                                navigate and use.
                              </li>
                            </ul>
                            <h6>Elevate Your Application Capabilities</h6>
                            <p>Partner with Satellitekey IT Solutions Pvt. Ltd. to advance your application development and support. Our experts deliver bespoke  solutions and comprehensive support to optimize performance and drive business success. Contact us today to discuss how our development and support services can enhance your applications, streamline your operations, and achieve strategic objectives with measurable impact.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="tab-3"
                    className={`tab-pane fade p-0 sample ${
                      isActiveTab === "tab-3" ? "active show" : ""
                    }`}
                    role="tabpanel"
                  >
                    <div className="row">
                      <div
                        className="col-lg-12 wow bounceInUp"
                        style={{ visibility: "visible" }}
                      >
                        <div className="ux-ui-main">
                          <img
                            className="img-fluid"
                            src={require('../../assets/images/consulting-banner.png')}
                          />
                          <h3 className="pt-3">it consulting strategy</h3>
                          <p>
                            At Satellitekey IT Solutions Pvt. Ltd., our IT
                            consulting strategy focuses on providing expert
                            guidance to help businesses harness technology
                            effectively. We partner with you to develop and
                            implement technology strategies that align with your
                            business goals, streamline operations, and drive
                            growth.
                          </p>
                          <h2 style={{color:"#006776", fontSize:"1.5rem"}}>Why Choose SateliteKey IT?</h2>
                          <div className="services-text1">
                            <h6>1. Comprehensive Assessment </h6>
                            <ul>
                              <li>
                                We begin with a thorough evaluation of your
                                existing IT infrastructure, processes, and
                                business objectives. This assessment allows us
                                to identify strengths, weaknesses, and
                                opportunities for improvement.
                              </li>
                            </ul>
                          </div>
                          <div className="services-text1">
                            <h6>2. Strategic Planning </h6>
                            <ul>
                              <li>
                                Based on our assessment, we craft a <strong>tailored IT
                                strategy</strong> that aligns with your business goals.
                                This plan includes recommendations for
                                technology investments, process improvements,
                                and strategic initiatives to enhance your IT
                                capabilities.
                              </li>
                              <li>
                                Technology Roadmap Our consultants develop a
                                clear technology roadmap that outlines the steps
                                needed to achieve your IT goals. This roadmap
                                includes timelines, milestones, and resource
                                requirements to ensure a smooth implementation
                                process.{" "}
                              </li>
                            </ul>
                          </div>
                          <div className="services-text1">
                            <h6>3. Technology Roadmap</h6>
                            <ul>
                              <li>
                                Our consultants develop a clear technology
                                roadmap that outlines the steps needed to
                                achieve your IT goals. This roadmap includes
                                timelines, milestones, and resource requirements
                                to ensure a smooth implementation process.
                              </li>
                            </ul>
                          </div>
                          <div className="services-text1">
                            <h6>4. Implementation Support</h6>
                            <ul>
                              <li>
                                We provide guidance throughout the
                                implementation of our recommended strategies.
                                This includes project management, vendor
                                selection, and overseeing the deployment of new
                                technologies or systems.{" "}
                              </li>
                            </ul>
                          </div>
                          <div className="services-text1">
                            <h6>5. Ongoing Optimization </h6>
                            <ul>
                              <li>
                                {" "}
                                After implementation, we offer ongoing support
                                and optimization services to ensure that your
                                technology continues to meet your evolving
                                business needs. This includes performance
                                monitoring, periodic reviews, and adjustments as
                                necessary.
                              </li>
                            </ul>
                          </div>
                          <div className="services-text1">
                            <h6>6. Risk Management and Compliance</h6>
                            <ul>
                              <li>
                                Our consultants identify potential IT risks and
                                ensure compliance with relevant regulations and
                                standards. We help you mitigate risks and ensure
                                that your technology investments are secure and
                                compliant.
                              </li>
                            </ul>
                            <h6>Ready to Revolutionize Your IT Strategy?</h6>
                            <p>
                              Unlock the full potential of your IT with best IT
                              services company , Satellitekey IT Solutions Pvt.
                              Ltd. Our expert consulting services are tailored
                              to optimize your technology infrastructure,
                              streamline operations, and drive innovation.Get in
                              touch with us today to discover how our strategic
                              IT solutions can propel your business forward and
                              achieve your technology goals.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="tab-4"
                    className={`tab-pane fade p-0 sample ${
                      isActiveTab === "tab-4" ? "active show" : ""
                    }`}
                    role="tabpanel"
                  >
                    <div className="row">
                      <div
                        className="col-lg-12 wow bounceInUp"
                        style={{ visibility: "visible" }}
                      >
                        <div className="ux-ui-main">
                          <img
                            className="img-fluid"
                            src={require('../../assets/images/software-banner.png')}
                          />
                          <h3 className="pt-3">custom software development</h3>
                          <p>
                            At Satellitekey It solution pvt.ltd, we concentrate
                            in developing custom software to meet your specific
                            business needs and objectives. Our skilled team of
                            developers, analysts, and engineers collaborate to
                            create software solutions that are ideally tailored
                            to your requirements, assuring efficiency,
                            scalability, and innovation.
                          </p>
                          <h2 style={{color:"#006776", fontSize:"1.5rem"}}>Why Choose SateliteKey IT?</h2>
                          <div className="services-text1">
                            <h6>1 . Personalized Solutions</h6>
                            <ul>
                              <li>
                                Each firm has its unique set of criteria and
                                objectives. Our custom software development
                                services are geared to meet your individual
                                requirements, whether you want to automate
                                processes, combine systems, or develop totally
                                new applications.
                              </li>
                            </ul>
                          </div>
                          <div className="services-text1">
                            <h6>2 . Full-Cycle Development</h6>
                            <p>
                              Our comprehensive approach to custom software
                              development ensures that every phase of your
                              project is handled with care:
                            </p>
                            <ul>
                              <li>
                                <strong>Discovery &amp; Analysis:</strong>We
                                begin with a thorough analysis of your needs,
                                goals, and existing systems to create a clear
                                roadmap for development.
                              </li>
                              <li>
                                <strong>Design &amp; Architecture:</strong>Our
                                team designs the software architecture and user
                                interfaces to ensure a seamless, intuitive user
                                experience.
                              </li>
                              <li>
                                <strong>Development:</strong> Using the latest
                                technologies and best practices, we build
                                robust, scalable, and high-performance software
                                tailored to your specifications.
                              </li>
                              <li>
                                <strong>
                                  Testing &amp; Quality Assurance:{" "}
                                </strong>
                                Rigorous testing is conducted to identify and
                                fix any issues, ensuring your software is
                                reliable and performs as expected.
                              </li>
                              <li>
                                <strong>Deployment &amp; Integration: </strong>
                                We manage the deployment process and integrate
                                the software with your existing systems to
                                ensure smooth operation.{" "}
                              </li>
                              <li>
                                <strong>Support &amp; Maintenance: </strong>
                                Post-launch, we provide ongoing support and
                                maintenance to address any issues and keep your
                                software up-to-date.{" "}
                              </li>
                            </ul>
                          </div>
                          <div className="services-text1">
                            <h6>3. Innovative Technologies</h6>
                            <ul>
                              <li>
                                We leverage cutting-edge technologies and
                                frameworks to deliver modern, high-performing
                                software solutions. Whether it’s cloud
                                computing, artificial intelligence, or advanced
                                data analytics, our team ensures your software
                                benefits from the latest innovations.
                              </li>
                            </ul>
                          </div>
                          <div className="services-text1">
                            <h6>4. Seamless Integration</h6>
                            <ul>
                              <li>
                                Our custom software integrates smoothly with
                                your existing systems and processes. We focus on
                                creating solutions that work harmoniously with
                                your current technology stack, enhancing overall
                                efficiency and productivity.
                              </li>
                            </ul>
                          </div>
                          <div className="services-text1">
                            <h6> Let’s Build Your Vision</h6>

                            <p>
                              Ready to transform your business with custom
                              software solutions? Contact us today to discuss
                              your project and learn how our custom software
                              development services can help you achieve your
                              goals. At Satellitekey It solution pvt.ltd, we’re
                              dedicated to delivering high-quality, tailor-made
                              software that drives success and innovation.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="tab-5"
                    className={`tab-pane fade p-0 sample ${
                      isActiveTab === "tab-5" ? "active show" : ""
                    }`}
                    role="tabpanel"
                  >
                    <div className="row">
                      <div
                        className="col-lg-12 wow bounceInUp"
                        style={{ visibility: "visible" }}
                      >
                        <div className="ux-ui-main">
                          <img
                            className="img-fluid"
                            src={require('../../assets/images/cloud-banner.png')}
                          />
                          <h3 className="pt-3">Cloud Computing Solutions</h3>
                          <p>
                            At Satellitekey It solution pvt.ltd, we harness the
                            power of cloud computing to help your business
                            achieve greater flexibility, scalability, and
                            efficiency. Our cloud solutions are designed to
                            support your evolving needs, streamline operations,
                            and drive innovation, all while ensuring your data
                            is secure and accessible.
                          </p>
                          <h2 style={{color:"#006776", fontSize:"1.5rem"}}>Why Choose SateliteKey IT?</h2>
                          <div className="services-text1">
                            <h6>1. Comprehensive Cloud Solutions</h6>
                            <p>
                              Our cloud computing services cover a wide range of
                              solutions to meet various business needs:
                            </p>
                            <ul>
                              <li>
                                <strong>
                                  Cloud Strategy &amp; Consulting:
                                </strong>{" "}
                                We work with you to develop a cloud strategy
                                that aligns with your business objectives,
                                providing guidance on cloud adoption, migration,
                                and optimization.
                              </li>
                              <li>
                                <strong>Cloud Migration: </strong> Seamlessly
                                transition your existing systems and data to the
                                cloud with minimal disruption. We handle every
                                aspect of the migration process, ensuring a
                                smooth and efficient transition.
                              </li>
                              <li>
                                <strong>
                                  Cloud Infrastructure Management:
                                </strong>{" "}
                                We design and manage scalable and reliable cloud
                                infrastructures that support your business
                                operations, including computing resources,
                                storage, and networking.
                              </li>
                              <li>
                                <strong>Cloud Application Development: </strong>{" "}
                                Develop and deploy cloud-native applications
                                that leverage the full potential of cloud
                                environments, including microservices,
                                serverless computing, and containerization.{" "}
                              </li>
                              <li>
                                <strong>
                                  Cloud Security &amp; Compliance:{" "}
                                </strong>{" "}
                                Implement robust security measures and ensure
                                compliance with industry standards and
                                regulations to protect your data and
                                applications in the cloud.{" "}
                              </li>
                              <li>
                                <strong>Cloud Optimization: </strong>{" "}
                                Continuously monitor and optimize your cloud
                                resources to improve performance and reduce
                                costs.
                              </li>
                            </ul>
                          </div>
                          <div className="services-text1">
                            <h6>2. Scalability &amp; Flexibility</h6>
                            <ul>
                              <li>
                                Our cloud solutions are designed to grow with
                                your business. Easily scale your resources up or
                                down based on demand, and adapt to changing
                                business needs without the constraints of
                                traditional IT infrastructure.
                              </li>
                            </ul>
                          </div>
                          <div className="services-text1">
                            <h6>3. Cost Efficiency</h6>
                            <ul>
                              <li>
                                Reduce capital expenditures and only pay for
                                what you use with our cloud solutions. By
                                leveraging the cloud, you can optimize your IT
                                budget and allocate resources more effectively.
                              </li>
                            </ul>
                          </div>
                          <div className="services-text1">
                            <h6>
                              4. Enhanced Collaboration &amp; Productivity
                            </h6>
                            <ul>
                              <li>
                                Cloud computing enables seamless collaboration
                                and access to your applications and data from
                                anywhere. Empower your team to work more
                                efficiently and stay connected, regardless of
                                location.
                              </li>
                            </ul>
                          </div>
                          <div className="services-text1">
                            <h6>
                              5. Disaster Recovery &amp; Business Continuity
                            </h6>
                            <ul>
                              <li>
                                Ensure your business can quickly recover from
                                unexpected disruptions with our cloud-based
                                disaster recovery solutions. We implement
                                strategies to protect your data and maintain
                                business continuity in case of emergencies.
                              </li>
                            </ul>
                          </div>
                          <div className="services-text1">
                            <h6>6. Our Expertise</h6>
                            <ul>
                              <li>
                                <strong>Public Cloud Services: </strong>{" "}
                                Leverage major public cloud platforms such as
                                AWS, Microsoft Azure, and Google Cloud to
                                benefit from their global infrastructure and
                                services.
                              </li>
                              <li>
                                <strong>Private Cloud Solutions: </strong> Build
                                a secure and dedicated cloud environment
                                tailored to your organization’s specific needs
                                and compliance requirements.
                              </li>
                              <li>
                                <strong>
                                  Hybrid &amp; Multi-Cloud Strategies:{" "}
                                </strong>{" "}
                                Combine public and private cloud resources to
                                create a flexible and efficient cloud
                                architecture that maximizes the benefits of
                                both.
                              </li>
                              <li>
                                <strong>Cloud DevOps: </strong> Implement DevOps
                                practices in the cloud to automate processes,
                                improve collaboration, and accelerate the
                                development and deployment of applications{" "}
                              </li>
                              <li>
                                <strong>Cloud Analytics: </strong> Utilize
                                cloud-based analytics tools to gain valuable
                                insights from your data and drive data-driven
                                decision-making.{" "}
                              </li>
                            </ul>
                          </div>
                          <div className="services-text1">
                            <h6>
                              Ready to Elevate Your Business with the Cloud?
                            </h6>

                            <p>
                              Unlock the full potential of cloud computing with
                              Satellitekey It solution pvt.ltd. Contact us today
                              to explore how our cloud services can transform
                              your operations, enhance your agility, and drive
                              growth. Our team of experts is here to guide you
                              every step of the way, ensuring a successful cloud
                              journey tailored to your business needs.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="tab-6"
                    className={`tab-pane fade p-0 sample ${
                      isActiveTab === "tab-6" ? "active show" : ""
                    }`}
                    role="tabpanel"
                  >
                    <div className="row">
                      <div
                        className="col-lg-12 wow bounceInUp"
                        style={{ visibility: "visible" }}
                      >
                        <div className="ux-ui-main">
                          <img
                            className="img-fluid"
                            src={require('../../assets/images/digtal-banner.png')}
                          />
                          <h3 className="pt-3">digital marketing services</h3>
                          <p>
                            At Satellitekey It solution pvt.ltd, we offer
                            comprehensive digital marketing services designed to
                            boost your online presence, engage your target
                            audience, and drive measurable results. Our team of
                            digital marketing experts uses data-driven
                            strategies and innovative techniques to help your
                            business stand out in the digital landscape and
                            achieve your marketing goals.
                          </p>
                          <h2 style={{color:"#006776", fontSize:"1.5rem"}}>Why Choose SateliteKey IT?</h2>
                          <div className="services-text1">
                            <h6>1. Strategic Approach</h6>
                            <ul>
                              <li>
                                We take a strategic approach to digital
                                marketing, tailoring our services to your unique
                                business objectives and target audience. From
                                initial planning to execution and analysis, we
                                create a customized marketing strategy that
                                aligns with your brand and drives results.
                              </li>
                            </ul>
                          </div>
                          <div className="services-text1">
                            <h6>2. Integrated Marketing Solutions</h6>
                            <p>
                              Our digital marketing services encompass a wide
                              range of solutions to cover all aspects of your
                              online presence:
                            </p>
                            <ul>
                              <li>
                                <strong>
                                  Search Engine Optimization (SEO):{" "}
                                </strong>{" "}
                                Improve your website’s visibility in search
                                engine results with our SEO strategies. We
                                optimize your site’s content, structure, and
                                keywords to attract organic traffic and enhance
                                your search rankings.
                              </li>
                              <li>
                                <strong>
                                  Pay-Per-Click (PPC) Advertising:
                                </strong>{" "}
                                Drive targeted traffic and achieve quick results
                                with our PPC campaigns. We manage ad creation,
                                bidding strategies, and performance tracking to
                                ensure a high return on investment.
                              </li>
                              <li>
                                <strong>Content Marketing: </strong> Engage your
                                audience with valuable and relevant content. We
                                develop content strategies, create compelling
                                articles, blog posts, infographics, and videos
                                that resonate with your audience and build brand
                                authority.
                              </li>
                              <li>
                                <strong>Social Media Marketing: </strong>{" "}
                                Enhance your brand’s presence and engage with
                                your audience across various social media
                                platforms. We manage social media campaigns,
                                create engaging content, and analyze performance
                                to grow your social media following and drive
                                engagement.
                              </li>
                              <li>
                                <strong>Email Marketing: </strong> Connect with
                                your audience through personalized and effective
                                email campaigns. We design and execute email
                                marketing strategies that nurture leads, drive
                                conversions, and build customer loyalty.{" "}
                              </li>
                              <li>
                                <strong>Online Reputation Management: </strong>{" "}
                                Manage and improve your online reputation with
                                our reputation management services. We monitor
                                reviews, respond to feedback, and implement
                                strategies to enhance your brand’s image and
                                credibility.
                              </li>
                              <li>
                                <strong>Analytics &amp; Reporting: </strong>{" "}
                                Measure and analyze the performance of your
                                digital marketing efforts with detailed
                                analytics and reporting. We track key metrics,
                                provide insights, and make data-driven
                                recommendations to optimize your campaigns.{" "}
                              </li>
                            </ul>
                          </div>
                          <div className="services-text1">
                            <h6>3. Results-Driven Focus</h6>
                            <ul>
                              <li>
                                Our focus is on delivering tangible results for
                                your business. We use data and analytics to
                                measure the success of our strategies, refine
                                our approach, and ensure that our efforts
                                contribute to your growth and success.
                              </li>
                            </ul>
                          </div>
                          <div className="services-text1">
                            <h6>4. Innovative Techniques</h6>
                            <ul>
                              <li>
                                We stay ahead of industry trends and leverage
                                the latest technologies and techniques to keep
                                your digital marketing strategies fresh and
                                effective. Our team is dedicated to implementing
                                innovative solutions that set your brand apart
                                from the competition.
                              </li>
                            </ul>
                          </div>
                          <div className="services-text1">
                            <h6>5. Our Expertise</h6>
                            <p>
                              In a world where users access applications across
                              various devices and screen sizes, our designs are
                              optimized for responsiveness and adaptability. We
                              ensure your product looks and functions
                              beautifully whether on a smartphone, tablet, or
                              desktop.
                            </p>
                            <ul>
                              <li>
                                <strong>SEO Audits &amp; Optimization: </strong>{" "}
                                Comprehensive audits and on-page/off-page
                                optimization to improve search engine rankings.
                              </li>
                              <li>
                                <strong>Ad Campaign Management: </strong>{" "}
                                Creation, management, and optimization of PPC
                                campaigns across platforms like Google Ads and
                                Bing Ads.
                              </li>
                              <li>
                                <strong>
                                  Content Creation &amp; Strategy:{" "}
                                </strong>{" "}
                                Development of high-quality content and
                                strategic planning to drive engagement and
                                authority.{" "}
                              </li>
                              <li>
                                <strong>Social Media Strategy:</strong>{" "}
                                Strategic planning and execution for platforms
                                like Facebook, Instagram, LinkedIn, Twitter, and
                                more.
                              </li>
                              <li>
                                <strong>Email Campaign Design: </strong>{" "}
                                Creation of effective email campaigns that drive
                                engagement and conversions.
                              </li>
                              <li>
                                <strong>Reputation Monitoring:</strong>{" "}
                                Proactive management of your online reputation
                                and response to customer feedback.
                              </li>
                            </ul>
                          </div>
                          <div className="services-text1">
                            <h6> Let’s Create Something Amazing Together</h6>

                            <p>
                              Partner with Satellitekey It solution pvt.ltd, to
                              elevate your digital marketing efforts and achieve
                              your business goals. Contact us today to discuss
                              how our digital marketing services can drive
                              growth, enhance your online presence, and deliver
                              measurable results for your business.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*about-us-end*/}
      {/*----middle-----end--------------------*/}
    </div>
  );
};

export default Services;
