import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../Home/Home";
import About from "../About/About";
import Blog from "../Blog/Blog";
import BlogDetails from "../BlogDetails/BlogDetails";
import Career from "../Career/Career";
import Client from "../Client/Client";
import Contact from "../Contact/Contact";
import Faq from "../Faq/Faq";
import Portfolio from "../Portfolio/Portfolio";
import Privacy from "../Privacy/Privacy";
import Services from "../Services/Services";
import Terms from "../Terms/Terms";
import Enquiry from "../Enquiry/Enquiry";
import CareerDetails from "../CareerDetails/CareerDetails";

const Router = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blogDetails/:id" element={<BlogDetails />} />
        <Route path="/careers" element={<Career />} />
        <Route path="/client" element={<Client />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/enquiry" element={<Enquiry />} />
        <Route path="/faq" element={<Faq />} />
        
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/Privacy" element={<Privacy />} />
        <Route path="/services" element={<Services />} />
        <Route path="/term" element={<Terms />} />
        <Route path="/career-details/:id" element={<CareerDetails />} />

        
        {/* <Route path="/ITSolutions/about" element={<About />} />
        <Route path="/ITSolutions/blog" element={<Blog />} />
        <Route path="/ITSolutions/blogDetails/:id" element={<BlogDetails />} />
        <Route path="/ITSolutions/career" element={<Career />} />
        <Route path="/ITSolutions/client" element={<Client />} />
        <Route path="/ITSolutions/contact" element={<Contact />} />
        <Route path="/ITSolutions/enquiry" element={<Enquiry />} />
        <Route path="/ITSolutions/faq" element={<Faq />} />
        <Route path="/ITSolutions/home" element={<Home />} />
        <Route path="/ITSolutions/portfolio" element={<Portfolio />} />
        <Route path="/ITSolutions/Privacy" element={<Privacy />} />
        <Route path="/ITSolutions/services" element={<Services />} />
        <Route path="/ITSolutions/term" element={<Terms />} />
        <Route path="/ITSolutions/career-details/:id" element={<CareerDetails />} /> */}
        <Route path="/ITSolutions/home" element={<Home />} />
        <Route path="/ITSolutions/career" element={<Career />} />
      </Routes>
    </div>
  );
};

export default Router;
