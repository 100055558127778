import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import headingLeft from '../../assets/images/heading_left.png'
import headingRight from '../../assets/images/heading_right.png'
import clientsIcon from '../../assets/images/clients-icon.png'
import SpotrackImg from '../../assets/images/spotrack.png'
import starImg from '../../assets/images/star.png'
import skymmdImg from '../../assets/images/skymmd.png'
import wwImg from '../../assets/images/ww.png'
import khomeImg from '../../assets/images/k-home.png'


const Client = () => {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 300,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplaySpeed: 3500,
    arrows:0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const handleLinkClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <div>
      {/*--------middle-----start-------*/}
      {/*counter--start*/}
      <div className="counter-main">
        <div className="about-men-bg client-men-bg">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div
                  className="about-men"
                  data-aos="flip-left"
                  data-aos-duration={500}
                >
                  <h2>CLIENT REVIEW</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="about-bottom-men">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="about-bottom">
                  <h6>
                    <label>
                    <Link to={'/'} onClick={handleLinkClick}>Home </Link>
                    </label>
                    / Client Review
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* counter---end*/}
      {/*--client-review-----start---*/}
      <div className="client-review-main">
        <div className="container">
          <div
            className="heading-main"
            data-aos="zoom-in"
            data-aos-duration={1000}
          >
            <h2>
              <img
                className="heading-image"
                src={headingLeft}
              />
              Client <span>Review</span>
              <img
                className="heading-image"
                src={headingRight}
              />
            </h2>
            <p>
              SatelliteKey IT Solution pvt.ltd. offers a comprehensive range of
              IT services to meet the needs of businesses of all sizes. Our
              services include:
            </p>
          </div>
          <Slider {...settings} className="row slider-a">
            <div className="col-md-12">
              <div
                className="details-box"
                aos-init
                aos-animate
                data-aos="zoom-in"
                data-aos-duration={1000}
              >
                <img
                  className="img-fluid client-icon ic-a"
                  src={clientsIcon}
                />
                <p>
                We at Spotrack are thrilled with the website SatelliteKey IT Solutions developed. Their team showcased exceptional professionalism and truly understood our brand, creating a visually stunning, user-friendly site. The attention to detail, seamless functionality, and innovative design have significantly enhanced our online presence and customer experience. We highly recommend them.
                </p>
                <div className="a2z">
                  <div>
                    <img
                      className="img-fluid client-icon spotrack"
                      src={SpotrackImg}
                    />
                  </div>
                  <div>
                    <img
                      className="img-fluid client-icon"
                      src={starImg}
                    />
                  </div>
                </div>
                <h5>Spotrack</h5>
                {/* <p>(CEO)</p> */}
              </div>
            </div>
            <div className="col-md-12">
              <div
                className="details-box"
                aos-init
                aos-animate
                data-aos="zoom-in"
                data-aos-duration={1000}
              >
                <img
                  className="img-fluid client-icon ic-a"
                  src={clientsIcon}
                />
                <p>
                Choosing SatelliteKey IT Solutions to build our website was a fantastic decision. They turned our vision into a modern, user-friendly site that perfectly reflects our brand. The design is sleek, and the functionality is smooth, leading to increased traffic and easier online bookings. We highly recommend them for exceptional web development services.
                </p>
                <div className="a2z">
                  <div>
                    <img
                      className="img-fluid client-icon skymmd"
                      src={skymmdImg}
                    />
                  </div>
                  <div>
                    <img
                      className="img-fluid client-icon"
                      src={starImg}
                    />
                  </div>
                </div>
                <h5>Sky MMD</h5>
                {/* <p>(CEO)</p> */}
              </div>
            </div>
            <div className="col-md-12">
              <div
                className="details-box"
                aos-init
                aos-animate
                data-aos="zoom-in"
                data-aos-duration={1000}
              >
                <img
                  className="img-fluid client-icon ic-a"
                  src={clientsIcon}
                />
                <p>
                We are thrilled with the website created by SatelliteKey IT Solutions. Their responsive team ensured the site reflected our brand’s focus on precision and innovation in GPS tracking. The sleek, modern design is fully optimized for mobile devices, crucial for our real-time tracking users. The development process was seamless, and they were always receptive to feedback. SatelliteKey IT Solutions has greatly enhanced our online presence, helping us better serve our customers.
                </p>
                <div className="a2z">
                  <div>
                    <img
                      className="img-fluid client-icon suugam"
                      src={wwImg}
                    />
                  </div>
                  <div>
                    <img
                      className="img-fluid client-icon"
                      src={starImg}
                    />
                  </div>
                </div>
                <h5>Suugam Techknow World Private Limited</h5>
                {/* <p>(CEO)</p> */}
              </div>
            </div>
            <div className="col-md-12">
              <div
                className="details-box"
                aos-init
                aos-animate
                data-aos="zoom-in"
                data-aos-duration={1000}
              >
                <img
                  className="img-fluid client-icon ic-a"
                  src={clientsIcon}
                />
                <p>
                SatelliteKey IT Solutions provided an excellent service, quickly understanding our needs and creating a website that reflects the luxury of our car detailing services. The site is fast, responsive, and user-friendly, making it easy for customers to book appointments. Their team's creativity and technical expertise are evident throughout. We're very satisfied with the outcome and highly recommend SatelliteKey IT Solutions for any business seeking a premium digital presence.
                </p>
                <div className="a2z">
                  <div>
                    <img
                      className="img-fluid client-icon khome"
                      src={khomeImg}
                    />
                  </div>
                  <div>
                    <img
                      className="img-fluid client-icon"
                      src={starImg}
                    />
                  </div>
                </div>
                <h5>K-Homes</h5>
                {/* <p>(CEO)</p> */}
              </div>
            </div>
            {/* <div className="col-md-12">
              <div
                className="details-box"
                aos-init
                aos-animate
                data-aos="zoom-in"
                data-aos-duration={1000}
              >
                <img
                  className="img-fluid client-icon"
                  src={client03}
                />
                <p>
                The team at SatelliteKey IT Solutions exceeded our expectations with their web development services. They understood our vision and transformed it into a website that not only looks incredible but also provides a smooth, hassle-free user experience. The site is fast, secure, and has all the features we needed to streamline our operations. Their attention to detail and commitment to quality have made a significant impact on our business. We’re thrilled with the results and look forward to working with them again in the future.
                </p>
                <div>
                  <img
                    className="img-fluid client-icon"
                    src="assets/images/client-1.png"
                  />
                </div>
                <div>
                  <img
                    className="img-fluid client-icon"
                    src={client05}
                  />
                </div>
                <h5>Rakesh Sharma</h5>
                <p>(CEO)</p>
              </div>
            </div>
            <div className="col-md-12">
              <div
                className="details-box"
                aos-init
                aos-animate
                data-aos="zoom-in"
                data-aos-duration={1000}
              >
                <img
                  className="img-fluid client-icon"
                  src={client03}
                />
                <p>
                From the moment we partnered with SatelliteKey IT Solutions, we knew we were in good hands. They took our ideas and turned them into a beautiful, functional website that truly represents our brand. The design is clean, the navigation is intuitive, and the performance is top-notch. Our customers have noticed the difference, and so have we. SatelliteKey IT Solutions has been a pleasure to work with, and we highly recommend them to anyone in need of a top-tier website
                </p>
                <div>
                  <img
                    className="img-fluid client-icon"
                    src="assets/images/client-1.png"
                  />
                </div>
                <div>
                  <img
                    className="img-fluid client-icon"
                    src={client05}
                  />
                </div>
                <h5>Rakesh Sharma</h5>
                <p>(CEO)</p>
              </div>
            </div> */}
          </Slider>
        </div>
      </div>
      {/*--client-review-----end--*/}
      {/*about-us-end*/}
      {/*----middle-----end--------------------*/}
    </div>
  );
};

export default Client;
