import React from "react";
import { Link } from "react-router-dom";

const Terms = () => {
  const handleLinkClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <div>
      {/*--------middle-----start-------*/}
      {/*counter--start*/}
      <div className="counter-main">
        <div className="about-men-bg terms-us-main">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div
                  className="about-men"
                  data-aos="flip-left"
                  data-aos-duration={500}
                >
                  <h2>Terms and Conditions</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="about-bottom-men">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="about-bottom">
                  <h6>
                    <label>
                    <Link to={'/'} onClick={handleLinkClick}>Home </Link>
                    </label>
                    / Terms and Conditions
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* counter---end*/}
      {/*about-us--start */}
      <div className="about-us-main">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-12">
              <div className="privacy-policy-text">
                <h6>Terms And Conditions of SatelliteKey IT Solutions</h6>
              </div>
              <div className="privacy-policy-text1">
                <h6>1. Introduction</h6>
                <ul>
                  <li>
                    Welcome to SatelliteKey IT Solutions ("we", "our", "us"). By
                    accessing or using our services, you agree to be bound by
                    these Terms and Conditions ("Terms"). Kindly refrain from
                    using our services if you disagree with or agree to these
                    Terms.
                  </li>
                </ul>
              </div>
              <div className="privacy-policy-text1">
                <h6>2. Services</h6>
                <ul>
                  <li>
                    SatelliteKey IT Solutions provides the following services:
                  </li>
                  <li>
                    UI/UX Designing: Creating user interfaces and experiences
                    for applications and websites.
                  </li>
                  <li>
                    App Development &amp; Support: Developing and maintaining
                    mobile and web applications.
                  </li>
                  <li>
                    IT Consulting Strategy: Providing expert advice on IT
                    strategies and solutions.
                  </li>
                  <li>
                    Custom Software Development: Building tailored software
                    solutions to meet specific client needs.
                  </li>
                  <li>
                    Cloud Computing: Offering cloud-based services and solutions
                    for storage, computing, and data management.
                  </li>
                  <li>
                    Digital Marketing Services: Implementing marketing
                    strategies, including SEO, SEM, and social media management.
                  </li>
                </ul>
              </div>
              <div className="privacy-policy-text1">
                <h6>3. User Responsibilities</h6>
                <ul>
                  <li>
                    Eligibility: You must be at least 18 years old to use our
                    services.
                  </li>
                  <li>
                    Account Information: You are responsible for maintaining the
                    confidentiality of your account details and for all
                    activities conducted under your account.
                  </li>
                  <li>
                    Prohibited Uses: You agree not to use our services for any
                    unlawful or unauthorized purposes, including but not limited
                    to unauthorized access to data, spamming, or distributing
                    malicious software.
                  </li>
                </ul>
              </div>
              <div className="privacy-policy-text1">
                <h6>4. Payment Terms</h6>
                <ul>
                  <li>
                    Fees: Our fees for services are detailed in the service
                    agreements or invoices provided. Fees are subject to
                    applicable taxes.
                  </li>
                  <li>
                    Payment: Payments are due as specified in the invoice or
                    service agreement. We reserve the right to charge late fees
                    or interest on overdue amounts as specified in the
                    agreement.
                  </li>
                  <li>
                    Refunds: Refund policies are outlined in the service
                    agreement. Any refunds are at our discretion and subject to
                    the terms of the agreement.
                  </li>
                </ul>
              </div>
              <div className="privacy-policy-text1">
                <h6>5. Intellectual Property</h6>
                <ul>
                  <li>
                    Ownership: All intellectual property rights in our services,
                    software, designs, and related materials are owned by
                    SatelliteKey IT Solutions or our licensors.
                  </li>
                  <li>
                    License: You are granted a limited, non-exclusive,
                    non-transferable license to use our services for your
                    personal or business use, subject to these Terms. You may
                    not copy, modify, or disturb
                  </li>
                </ul>
              </div>
              <div className="privacy-policy-text1">
                <h6>6. Confidentiality</h6>
                <ul>
                  <li>
                    Confidential Information: Both parties agree to maintain the
                    confidentiality of any information marked as confidential or
                    that should reasonably be understood as confidential given
                    the context of disclosure.
                  </li>
                  <li>
                    Disclosure: Confidential information may only be disclosed
                    if required by law or with prior written consent from the
                    disclosing party.
                  </li>
                </ul>
              </div>
              <div className="privacy-policy-text1">
                <h6>7. Termination</h6>
                <ul>
                  <li>
                    Termination by You: You may terminate your use of our
                    services by providing written notice as specified in your
                    service agreement. Any fees paid are non-refundable, except
                    as outlined in the agreement.
                  </li>
                  <li>
                    Termination by Us: We may terminate or suspend your access
                    to our services if you breach these Terms or if required by
                    law. We will provide notice of termination or suspension as
                    required.
                  </li>
                </ul>
              </div>
              <div className="privacy-policy-text1">
                <h6>8. Governing Law</h6>
                <ul>
                  <li>
                    These Terms are governed by and construed in accordance with
                    the laws of company. Any disputes arising under or in
                    connection with these Terms will be subject to the exclusive
                    jurisdiction of the courts in Jaipur.
                  </li>
                </ul>
              </div>
              <div className="privacy-policy-text1">
                <h6>9. Changes to Terms</h6>
                <ul>
                  <li>
                    We may update these Terms from time to time. Any significant
                    changes will be communicated via our website or other
                    appropriate means. Continued use of our services after
                    changes constitutes acceptance of the updated Terms.
                  </li>
                </ul>
              </div>
              <div className="privacy-policy-text1">
                <h6>10. Contact Information</h6>
                <ul>
                  <li>
                    For any questions or concerns regarding these Terms, please
                    contact us at:
                  </li>
                  <li>
                    <b>Email: info@satellitekeyit.com</b>
                  </li>
                  <li>
                    <b>Phone: +91 95298 99325</b>
                  </li>
                  <li>
                    <b>
                      Address: Yogi Tower, Mahalaxmi Nagar Road, D-Block,
                      Malviya Nagar, Jaipur - 302017, Rajasthan
                    </b>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*about-us-end*/}
      {/*----middle-----end--------------------*/}
    </div>
  );
};

export default Terms;
