import { useEffect } from "react";
import Footer from "./Components/Footer/Footer";
import Navbar from "./Components/Navbar/Navbar";
import Router from "./Components/Router/Router";
import LanguageSwitcher from "./Langswitch/Langswitch";
import AOS from 'aos';
import 'aos/dist/aos.css';
import './App.css'


function App() {
  useEffect( () => {
     setTimeout(() => {
      AOS.init();  
     }, 500);
     
  },[])

  return (
    <div className="App">
    <div className="navbar-aa">
       <Navbar />
       {/* <LanguageSwitcher/> */}
    </div>
    <div className="router-aa">
       <Router/>
    </div>
       <Footer/>
    </div>
  );
}

export default App;
