import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import blogDetails01 from "../../assets/images/blog-detail.png";
import axios from "axios";
import toast from "react-hot-toast";

const BlogDetails = () => {
  const [Loading, setLoading] = useState(false);
  const [blogDetailData, setBlogDetailData] = useState([]);

  const handleLinkClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const { id } = useParams();
  useEffect(() => {
    const apiData = {
      id: id,
    };

    const GetBlogApi = async (apiData) => {
      const data = await axios.get(
        `https://service-spotrack-dubphwg5cae3f4da.centralindia-01.azurewebsites.net/api/v1/blog/get_blog?blog_id=${apiData.id}`
      );

      return data;
    };

    GetBlogApi(apiData)
      .then((res) => {
        setLoading(true);
        console.log(res.data.responseObj.result);
        setBlogDetailData(res.data.responseObj.result);
        setTimeout(() => {
          setLoading(false);
        }, 100);
      })
      .catch((error) => {
        console.log("Error fetching blogs", error);
        toast("Something went wrong meaning");
      });
  }, []);

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  return (
    <div>
      {/*--------middle-----start-------*/}
      {/*counter--start*/}
      <div className="counter-main">
        <div className="about-men-bg blog-us-main">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div
                  className="about-men"
                  data-aos="flip-left"
                  data-aos-duration={500}
                >
                  <h2>BLOG DETAILS</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="about-bottom-men">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="about-bottom">
                  <h6>
                    <label>
                      <Link to={"/"} onClick={handleLinkClick}>
                        Home{" "}
                      </Link>
                    </label>
                    / Blog Details
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* counter---end*/}
      {/*about-us--start */}
      <div className="about-us-main">
        <div className="container">
          <div className="row">
            {Loading ? (
              <div style={{ textAlign: "center" }}>
                <h2>Loading...</h2>
              </div>
            ) : (
              <>
                <div className="col-md-8 col-lg-8 mb-4">
                  <div className="blog-men-text">
                    <img
                      className="img-fluid"
                      src={`${blogDetailData.blog_image}`}
                      alt="IT Solutions Blog Image"
                    />
                    <div className="row blog-text blog-text2">
                      <div className="col-lg-6">
                        <div className="blog-name">
                          <h6>
                            <i className="fa-regular fa-user" />
                            &nbsp;By {blogDetailData?.blog_author}
                          </h6>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="blog-name float-end">
                          <h6>
                            <i className="fa-solid fa-calendar-days" />
                            &nbsp;{formatDate(blogDetailData.created_on)}
                          </h6>
                        </div>
                      </div>
                      <div className="col-lg-12 pt-3">
                        <div className="blog-text1">
                          <h6>{blogDetailData.blog_title}</h6>
                          <p>{blogDetailData.content}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 col-lg-4 mb-4">
                  <div className="blog-men-text blog-men-text-two">
                    <div className="row blog-text blog-text2">
                      <div className="col-lg-12">
                        <div className="search-man">
                          <input
                            className="search-btn"
                            type="search"
                            placeholder="Search....."
                          />
                          <label>
                            <i className="fa-solid fa-magnifying-glass" />
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="search-man search-man-two">
                          <label>Categories</label>
                          <select className="categories-btn">
                            <option>Select Category</option>
                            <option>1</option>
                            <option>1</option>
                            <option>1</option>
                            <option>1</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {/*about-us-end*/}
      {/*----middle-----end--------------------*/}
    </div>
  );
};

export default BlogDetails;
