import React, { useState } from "react";
import { Link } from "react-router-dom";
import validator from "validator";
import axios from "axios";
import toast from 'react-hot-toast'
import contactImg from '../../assets/images/contact.png'

const Contact = () => {
  const [data, SetData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
    number: "",
    send: "Send Message",
  });

  const [error, setError] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
    number: "",
  });

  const handlePostRequest = async (event) => {
    try {
      event.preventDefault();

      let tempError = {};

      // name validation
      if (!data.name) {
        setError((prev) => ({ ...prev, name: "Name Is Required" }));
        tempError.name = "Name Is Required";
      } else if (
        !validator.isAlpha(data.name.replace(/\s+/g, ""), "en-US", {
          ignore: " ",
        })
      ) {
        setError((prev) => ({
          ...prev,
          name: "Name should only contain letters or spaces",
        }));
        tempError.name = "Name should only contain letters or spaces";
      } else {
        setError((prev) => ({ ...prev, name: "" }));
        tempError.name = "";
      }

      // email validation
      if (!data.email) {
        setError((prev) => ({ ...prev, email: "Email Is Required" }));
        tempError.number = "Email Is Required";
      } else if (!validator.isEmail(data.email)) {
        setError((prev) => ({ ...prev, email: "Invalid Email" }));
        tempError.email = "Invalid Email";
      } else {
        setError((prev) => ({ ...prev, email: "" }));
        tempError.email = "";
      }

      // subject validation
      if (!data.subject) {
        setError((prev) => ({ ...prev, subject: "Subject is Required" }));
        tempError.subject = "subject Is Required";
      } else {
        setError((prev) => ({ ...prev, subject: "" }));
        tempError.subject = "";
      }

      const regExForContact =
        /^\s*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
      if (!data.number) {
        setError((prev) => ({ ...prev, number: "Number Is Required" }));
        tempError.number = "Number Is Required";
      } else if (!regExForContact.test(data.number)) {
        setError((prev) => ({ ...prev, number: "Invalid Mobile Number" }));
        tempError.number = "Invalid Mobile Number";
      } else {
        setError((prev) => ({ ...prev, number: "" }));
        tempError.number = "";
      }

      // message validation
      if (!data.message) {
        setError((prev) => ({ ...prev, message: "Message is Required" }));
        tempError.message = "Message Is Required";
      } else {
        setError((prev) => ({ ...prev, message: "" }));
        tempError.message = "";
      }

      if (
        tempError.message ||
        tempError.email ||
        tempError.name ||
        tempError.subject ||
        tempError.number
      ) {
        return;
      }

      const formData = {
        name: data.name,
        email: data.email.replaceAll(' ',''),
        subject: data.subject,
        message: data.message,
        company_id: "9B1F21C0-D3EC-46E2-9554-58148EB1BFDF",
        contact: data.number,
      };

      SetData((prev)=>({...prev , send:"Sending..."} ));


      let res = await axios.post(
        "https://service-spotrack-dubphwg5cae3f4da.centralindia-01.azurewebsites.net/api/v1/inquiry/add-inquiry",
        formData
      );
      let to = res.data.result[0].result;
      toast.success(to);

      SetData({
        name: "",
        email: "",
        subject: "",
        message: "",
        number: "",
        send: "Send Message",
      });
      
    } catch (error) {
      console.log(error)
      SetData({
        name: "",
        email: "",
        subject: "",
        message: "",
        number: "",
        send: "Send Message",
      });
      toast.error(error?.response?.data?.message);
    }
  };
  const handleLinkClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <div>
      {/*--------middle-----start-------*/}
      {/*counter--start*/}
      <div className="counter-main">
        <div className="about-men-bg contact-us-main">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div
                  className="about-men"
                  data-aos="flip-left"
                  data-aos-duration={500}
                >
                  <h2>CONTACT</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="about-bottom-men">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="about-bottom">
                  <h6>
                    <label>
                      <Link to={"/"} onClick={handleLinkClick}>Home </Link>
                    </label>
                    / CONTACT
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* counter---end*/}
      {/*about-us--start */}
      <div className="about-us-main">
        <div className="container">
          <div className="row">
            <div
              className="col-md-6 col-lg-4"
              data-aos="zoom-in"
              data-aos-duration={1000}
            >
              <div className="contact-men">
                <div className="row align-items-center">
                  <div className="col-3 col-lg-3">
                    <div className="contact-box">
                      <i className="fa-solid fa-phone-volume" />
                    </div>
                  </div>
                  <div className="col-9 col-lg-9 p-0">
                    <div className="contact-box-text">
                      <h6>Call</h6>
                      <label>+91 9529899325</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-4"
              data-aos="zoom-in"
              data-aos-duration={1000}
            >
              <div className="contact-men">
                <div className="row align-items-center">
                  <div className="col-3 col-lg-3">
                    <div className="contact-box">
                      <i className="fa-regular fa-envelope" />
                    </div>
                  </div>
                  <div className="col-9 col-lg-9 p-0">
                    <div className="contact-box-text">
                      <h6>Email</h6>
                      <label>info@satellitekeyit.com</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-4"
              data-aos="zoom-in"
              data-aos-duration={1000}
            >
              <div className="contact-men Location-repons">
                <div className="row align-items-center">
                  <div className="col-3 col-lg-3">
                    <div className="contact-box">
                      <i className="fa-solid fa-location-dot" />
                    </div>
                  </div>
                  <div className="col-9 col-lg-9 p-0">
                    <div className="contact-box-text loc">
                      <h6>Location</h6>
                      <label>
                        Yogi Tower, Mahalaxmi Nagar Road, D-Block, Malviya
                        Nagar, Jaipur - 302017, Rajasthan
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div
              className="col-md-6 col-lg-6"
              data-aos="zoom-in"
              data-aos-duration={1000}
            >
              <div className="contact-men-form">
                <h4>Contact Form</h4>
                <form onSubmit={(e)=>handlePostRequest(e)}>
                  <input
                    className="input-btn"
                    type="text"
                    placeholder="Your name *"
                    value={data.name}
                    onChange={(e) =>{
                      SetData((prev) => ({ ...prev, name: e.target.value }))
                      setError((prev)=>({...prev, name:""}))
                    }
                    }
                  />
                  <div className="error-aa">{error.name}</div>
                  <input
                    className="input-btn"
                    type="text"
                    placeholder="Your Email *"
                    value={data.email}
                    onChange={(e) =>{
                      SetData((prev) => ({ ...prev, email: e.target.value }))
                      setError((prev)=>({...prev, email:""}))
                    }
                    }
                  />
                  <div className="error-aa">{error.email}</div>
                  <input
                    className="input-btn"
                    type="text"
                    placeholder="Contact No.*"
                    value={data.number}
                    onChange={(e) =>{
                      SetData((prev) => ({ ...prev, number: e.target.value }))
                      setError((prev)=>({...prev, number:""}))
                    }
                    }
                  />
                  <div className="error-aa">{error.number}</div>
                  <input
                    className="input-btn"
                    type="text"
                    placeholder="Subject *"
                    value={data.subject}
                    onChange={(e) =>{
                      SetData((prev) => ({ ...prev, subject: e.target.value }))
                      setError((prev)=>({...prev, subject:""}))
                    }
                    }
                  />
                  <div className="error-aa">{error.subject}</div>
                  <textarea
                    className="input-btn input-btn1"
                    placeholder="Message....."
                    value={data.message}
                    onChange={(e) =>{
                      SetData((prev) => ({ ...prev, message: e.target.value }))
                      setError((prev)=>({...prev, message:""}))
                    }
                    }
                  />
                  <div className="error-aa">{error.message}</div>
                  <button className="send-message-btn" type="submit">{data.send}</button>
                </form>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-6"
              data-aos="zoom-in"
              data-aos-duration={1000}
            >
              <div className="contact-men-form">
                <img className="img-fluid" src={contactImg} />
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div
              className="col-md-12 col-lg-12"
              data-aos="zoom-in"
              data-aos-duration={1000}
            >
              <div className="contact-men-form">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d28477.02098114824!2d75.76725337431641!3d26.85179380000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396db5d69950e079%3A0xe71d3d472f718bd6!2sSUUGAM%20TECHKNOW%20WORLD%20PRIVATE%20LIMITED!5e0!3m2!1sen!2sin!4v1723092966616!5m2!1sen!2sin"
                  width="100%"
                  height={450}
                  style={{ border: 0 }}
                  allowFullScreen
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*about-us-end*/}
      {/*----middle-----end--------------------*/}
    </div>
  );

};

export default Contact;
