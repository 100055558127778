import React from "react";
import { Link } from "react-router-dom";

const Privacy = () => {
  const handleLinkClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <div>
      {/*--------middle-----start-------*/}
      {/*counter--start*/}
      <div className="counter-main">
        <div className="about-men-bg privacy-us-main">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div
                  className="about-men"
                  data-aos="flip-left"
                  data-aos-duration={500}
                >
                  <h2>Privacy Policy </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="about-bottom-men">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="about-bottom">
                  <h6>
                    <label>
                    <Link to={'/'} onClick={handleLinkClick}>Home </Link>
                    </label>
                    / Privacy Policy
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* counter---end*/}
      {/*about-us--start */}
      <div className="about-us-main">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-12">
              <div className="privacy-policy-text">
                <h6>Privacy Policy for SatelliteKey IT Solutions</h6>
              </div>
              <div className="privacy-policy-text1">
                <h6>1. Introduction</h6>
                <ul>
                  <li>
                    Welcome to SatelliteKey IT Solutions (also referred to below
                    as "we," "our," "us"). At SatelliteKey IT Solutions, we
                    prioritize your privacy and are dedicated to protecting your
                    personal information with the highest standards of security.
                    This Privacy Policy explains how we collect, use, disclose,
                    and safeguard your data when you engage with our services,
                    which include UI/UX Design, App Development and Support, IT
                    Consulting Strategy, Custom Software Development, Cloud
                    Computing, and Digital Marketing Services.
                  </li>
                </ul>
              </div>
              <div className="privacy-policy-text1">
                <h6>2. Information We Collect</h6>
                <p>
                  To effectively offer and enhance our range of services,
                  SatelliteKey IT Solutions collects a variety of information
                  from you, and this information includes:
                </p>
                <ul>
                  <li>
                    <strong>Personal Identification Information: </strong>
                    Details such as your name, email address, phone number, and
                    other contact information. This helps us communicate with
                    you, manage your account, and provide you with customer
                    support.
                  </li>
                  <li>
                    <strong>Business Information: Information: </strong>about
                    your organization, including company name, job title,
                    industry, and any other relevant business details. This
                    allows us to tailor our services to better meet the specific
                    needs of your business.
                  </li>
                  <li>
                    <strong>Technical Data: Includes: </strong> your IP address,
                    browser type, operating system, device information, and
                    other technical details. This data is used to ensure the
                    compatibility, performance, and security of our services.
                  </li>
                  <li>
                    <strong>Service Usage Data: Information: </strong>on how you
                    interact with our services, such as the pages you visit,
                    features you use, and the duration of your engagement. This
                    data helps us understand your preferences and improve our
                    service offerings.{" "}
                  </li>
                </ul>
              </div>
              <div className="privacy-policy-text1">
                <h6>3. How We Use Your Information</h6>
                <ul>
                  <li>
                    <strong>To Provide and Maintain Services: </strong>We
                    utilize your information to deliver the services you have
                    requested, ensuring they are executed effectively and
                    efficiently. This includes managing your account, processing
                    transactions, and addressing any technical issues to
                    guarantee seamless operation.
                  </li>
                  <li>
                    <strong>To Improve Our Services: </strong>Your data helps us
                    analyze usage patterns and identify areas for enhancement.
                    By understanding how you interact with our services, we can
                    refine our offerings, introduce new features, and improve
                    overall quality to better meet your needs and preferences.
                  </li>
                  <li>
                    <strong>To Communicate with You: </strong>We use your
                    contact information to respond to your inquiries, provide
                    updates about our services, and keep you informed about
                    important changes, such as service updates, policy changes,
                    or issues that may affect your experience.
                  </li>
                  <li>
                    <strong>To Comply with Legal Obligations: </strong>We use
                    your information to adhere to legal requirements and
                    regulations, resolve disputes, and ensure that we are
                    following applicable laws. This includes fulfilling legal
                    obligations, responding to legal requests, and protecting
                    our rights and interests.
                  </li>
                </ul>
              </div>
              <div className="privacy-policy-text1">
                <h6>4. Sharing Your Information</h6>
                <p>
                  We do not sell or rent your personal information to third
                  parties. However, we may share your information in the
                  following scenarios:
                </p>
                <ul>
                  <li>
                    <strong>With Service Providers: </strong>We may share
                    information with third-party vendors and partners who assist
                    us in delivering our services. These parties are bound by
                    confidentiality agreements and are only permitted to use
                    your information as necessary to perform their functions.
                  </li>
                  <li>
                    <strong>For Legal Reasons: </strong>We may share your
                    information with government authorities or regulatory
                    agencies when we receive a legitimate request or demand for
                    such information. This can include situations where we are
                    required to provide information to aid in investigations or
                    legal proceedings.
                  </li>
                  <li>
                    <strong>Business Transfers: </strong>In the event of a
                    merger, acquisition, sale, or other reorganization of our
                    business, your information may be transferred to the new
                    entity as part of the transaction. Such transfers will be
                    governed by the terms of this Privacy Policy, and we will
                    ensure that the new entity adheres to similar privacy and
                    data protection standards.
                  </li>
                </ul>
              </div>
              <div className="privacy-policy-text1">
                <h6>5. Data Security</h6>
                <p>
                  We implement appropriate technical and organizational measures
                  to protect your personal information against unauthorized
                  access, alteration, disclosure, or destruction. Despite these
                  measures, no security system is impenetrable, and we cannot
                  guarantee the absolute security of your information.
                </p>
              </div>
              <div className="privacy-policy-text1">
                <h6>6. Data Retention</h6>
                <p>
                  We retain your information only as long as necessary to
                  fulfill the purposes outlined in this Privacy Policy, comply
                  with legal obligations, and resolve disputes. Once your
                  information is no longer needed, we will securely delete or
                  anonymize it.
                </p>
              </div>
              <div className="privacy-policy-text1">
                <h6>7. Your Rights and Choices</h6>
                <p>
                  Depending on your jurisdiction, you may have certain rights
                  regarding your personal information, including:
                </p>
                <ul>
                  <li>
                    <strong>Access and Correction: </strong>You have the right
                    to request access to your personal information that we hold
                    and to request correction of any inaccuracies. To do so,
                    please contact us at [info@satellitekeyit.com], and we will
                    provide you with the necessary instructions to facilitate
                    your request.
                  </li>
                  <li>
                    <strong>Data Portability:</strong>You may request a copy of
                    your personal information in a structured, commonly used
                    format.
                  </li>
                  <li>
                    <strong>Withdrawal of Consent: </strong>we rely on your
                    consent to process your personal information, you have the
                    right to withdraw that consent at any time. Please note that
                    withdrawing consent may affect our ability to provide
                    certain services.
                  </li>
                  <li>
                    <strong>Opt-Out of Marketing: </strong>You can opt-out of
                    receiving marketing communications from us at any time. To
                    unsubscribe, you can use the opt-out link included in our
                    marketing emails or contact us directly at [+91 95298
                    99325]. We will promptly honor your request and remove you
                    from our marketing lists.
                  </li>
                </ul>
              </div>
              <div className="privacy-policy-text1">
                <h6>8. Third-Party Links</h6>
                <p>
                  Our website and services may contain links to third-party
                  websites or services. We are not responsible for the privacy
                  practices or content of these third parties. We encourage you
                  to review the privacy policies of any third-party sites you
                  visit.
                </p>
              </div>
              <div className="privacy-policy-text1">
                <h6>9. Children's Privacy</h6>
                <p>
                  Our services are not intended for individuals under the age of
                  18. We do not knowingly collect or solicit personal
                  information from children under 18. If we become aware that we
                  have collected personal information from a child under 18, we
                  will take steps to delete such information.
                </p>
              </div>
              <div className="privacy-policy-text1">
                <h6>10. Changes to This Privacy Policy</h6>
                <p>
                  We may update this Privacy Policy from time to time to reflect
                  changes in our practices or legal requirements. We will notify
                  you of significant changes by posting the revised policy on
                  our website with an updated effective date.
                </p>
              </div>
              <div className="privacy-policy-text1">
                <h6>11. Contact Us</h6>
                <p>
                  If you have any questions, concerns, or requests regarding
                  this Privacy Policy or our data practices, please contact us
                  at:
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*about-us-end*/}
      {/*----middle-----end--------------------*/}
    </div>
  );
};

export default Privacy;
