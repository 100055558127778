import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Faq = () => {
  const handleLinkClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const [flag, setFlag] = useState(false);
  const [faqData, setFaqData] = useState();

  useEffect(() => {
    const func = async () => {
      setFlag(true);
      let res = await axios.get(
        "https://service-spotrack-dubphwg5cae3f4da.centralindia-01.azurewebsites.net/api/v1/faq/get_all_faq?company_id=9B1F21C0-D3EC-46E2-9554-58148EB1BFDF&search&page_no=1&rows_per_page=20"
      );

      
      res = res.data.responseObj.result;
      
      setFaqData(res);
    };
    func();

    setTimeout(() => {
      setFlag(false)
    }, 100);
  }, []);
  return (
    <div>
      {/*--------middle-----start-------*/}
      {/*counter--start*/}
      <div className="counter-main">
        <div className="about-men-bg client-men-bg help-men-bg fqa-men-bg">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div
                  className="about-men"
                  data-aos="flip-left"
                  data-aos-duration={500}
                >
                  <h2>Frequently Asked Questions</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="about-bottom-men">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="about-bottom">
                  <h6>
                    <label>
                      <Link to={"/"} onClick={handleLinkClick}>
                        Home{" "}
                      </Link>
                    </label>
                    / Frequently Asked Questions
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* counter---end*/}
      {/*--help-----start---*/}
      <div className="client-review-main help-main">
        <div className="container">
          <div className="accordion" id="accordionExample">
            {flag ? (
              <div style={{ textAlign: "center" }}>
                <h4>Loading...</h4>
              </div>
            ) : (
              faqData &&
              faqData.map((item,index) => (
                <div className="accordion-item">
                  <h2 className="accordion-header" id={`heading${index}`} key={index}>
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#collapse${index}`}
                      aria-expanded="false"
                      aria-controls={`collapse${index}`}
                    >
                      {index+1}. {item.question}
                    </button>
                  </h2>
                  <div
                    id={`collapse${index}`}
                    className="accordion-collapse collapse"
                    aria-labelledby={`heading${index}`}
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p>{item.answer}</p>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
      {/*--help-----end--*/}
      {/*----middle-----end--------------------*/}
      <br />
    </div>
  );
};

export default Faq;
