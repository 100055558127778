import React from "react";
import { Link } from "react-router-dom";
import aboutImg1 from '../../assets/images/about.png'
import aboutImg2 from '../../assets/images/vision.png'
import aboutImg3 from '../../assets/images/mission.png'
import { useTranslation } from "react-i18next";
import SEO from '../SEO/SEO'

const About = () => {
  const { t } = useTranslation();

  const handleLinkClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <div>
    {/* <SEO
      canonical={"https://satellitekeyit.com/about"}
    /> */}
      <div>
        {/*--------middle-----start-------*/}
        {/*counter--start*/}
        <div className="counter-main">
          <div className="about-men-bg">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div
                    className="about-men"
                    data-aos="flip-left"
                    data-aos-duration={500}
                  >
                    <h2>About Us</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="about-bottom-men">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="about-bottom">
                    <h6>
                      <label>
                      <Link to={'/'} onClick={handleLinkClick}>Home </Link>
                      </label>
                      / About Us
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* counter---end*/}
        {/*about-us--start */}
        <div className="about-us-main">
          <div className="container">
            <div className="row">
              <div
                className="col-12 col-md-5 col-lg-5 aboutImage-az"
                data-aos="zoom-in"
                data-aos-duration={1000}
              >
                <img className="img-fluid" src={aboutImg1} alt="IT Solutions" />
              </div>
              <div
                className="col-12 col-md-7 col-lg-7 aboutContent-az"
                data-aos="zoom-in"
                data-aos-duration={1000}
              >
                <div className="about-content">
                  <h4>About Us</h4>
                  <h1>
                    Welcome To <span>SatelliteKey IT Solutions</span>
                  </h1>
                  <p>
                    Welcome to SatelliteKey IT Solutions pvt.ltd., a leading IT
                    company dedicated to delivering innovative solutions and
                    driving digital transformation. With a strong focus on
                    excellence and customer satisfaction, we pride ourselves on
                    our ability to provide cutting-edge technology services
                    tailored to meet the unique needs of businesses.
                  </p>
                  <p>
                    We are a dynamic and innovative company dedicated to
                    providing customized software to meet the unique needs of
                    its customers. With excellence and customer satisfaction in
                    mind, we focus on building customized applications and
                    providing comprehensive software services designed to
                    increase business efficiency and productivity.
                  </p>
                  <p>
                    At SatelliteKey IT Solutions pvt.ltd., our team of skilled
                    professionals combines their expertise with a passion for
                    delivering exceptional results. We offer a broad spectrum of
                    services designed to empower businesses and enhance
                    operational efficiency. Our offerings include bespoke
                    software development, where we craft tailored applications
                    to meet specific business requirements.
                  </p>
                  <p>
                    Partner with us to leverage the power of technology and gain
                    a competitive edge in the digital landscape. our dedication
                    to excellence and customer satisfaction is unwavering. We
                    are more than just a service provider; we are your trusted
                    partner in navigating the complexities of the IT world. Let
                    us help you unlock new opportunities and achieve your
                    business aspirations with confidence.
                  </p>
                </div>
              </div>
             
            </div>
          </div>
        </div>
        {/*about-us-end*/}
        {/*about-us--start */}
        <div className="about-us-main">
          <div className="container">
            <div className="row">
              <div
                className="col-12 col-md-7 col-lg-7"
                data-aos="zoom-in"
                data-aos-duration={1000}
              >
                <div className="about-content">
                  <h4>Vision</h4>
                  <h2>
                    Our <span>Vision</span>
                  </h2>
                  <p>
                    We nurture a culture of out-of-the-box thinking, placing
                    innovation and creativity at the heart of everything we do.
                    Our focus is on creating the workplace of tomorrow—one that
                    rewards individual contributions while celebrating a
                    collaborative approach.{" "}
                  </p>
                  <p>
                    Our team thrives in an environment that encourages bold
                    ideas and collective problem-solving. This ensures that each
                    project benefits from a diverse range of insights and
                    talents. By blending individual excellence with a strong
                    emphasis on teamwork, we create a dynamic and
                    forward-thinking environment. This drives our success and
                    supports the growth of our clients
                  </p>
                </div>
              </div>
              <div
                className="col-12 col-md-5 col-lg-5"
                data-aos="zoom-in"
                data-aos-duration={1000}
              >
                <img className="img-fluid" src={aboutImg2} alt="IT Solutions" />
              </div>
            </div>
          </div>
        </div>
        {/*about-us-end*/}
        {/*about-us--start */}
        <div className="about-us-main">
          <div className="container">
            <div className="row">
              <div
                className="col-12 col-md-5 col-lg-5"
                data-aos="zoom-in"
                data-aos-duration={1000}
              >
                <img
                  className="img-fluid"
                  src={aboutImg3}
                  alt
                />
              </div>
              <div
                className="col-12 col-md-7 col-lg-7"
                data-aos="zoom-in"
                data-aos-duration={1000}
              >
                <div className="about-content">
                  <h4>Mission</h4>
                  <h2>
                    Our <span>Mission</span>
                  </h2>
                  <p>
                    Our name embodies our mission: to be the key that unlocks
                    potential and navigates you to new heights in the digital
                    landscape by providing companies with cutting-edge
                    technology solutions that drive growth, streamline
                    operations and achieve strategic goals. We specialize in
                    creating innovative software that is not just high-quality
                    and reliable but also scalable to adapt to your evolving
                    needs.
                    <br />
                    By focusing on precision and excellence, we ensure that our IT
                    solutions exceed expectations and contribute meaningfully to
                    your success in the digital age. Our dedicated team of
                    experts works closely with you to understand your unique
                    challenges and deliver solutions that propel your business growth
                    , enabling you to stay ahead in a rapidly changing
                    technological environment.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*about-us-end*/}
        {/*----middle-----end--------------------*/}
      </div>
    </div>
  );
};

export default About;
