import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import "./CareerDetails.css";
import ApplyModal from "../ApplyModal/ApplyModal";

const CareerDetails = () => {
  
  const { id } = useParams();
  const [job, setJob] = useState([]);
  const [flag, setFlag] = useState(false);
  const [keyResponsibilities, setKeyResponsibilitiese] = useState(null);
  const [techReq, setTecReq] = useState(null);

  useEffect(() => {
    try {
      const fuun = async () => {
        setFlag(true);
        let { data } = await axios.get(
          `https://service-spotrack-dubphwg5cae3f4da.centralindia-01.azurewebsites.net/api/v1/career/get_single_career?carrer_id=${id}`
        );

        data = data.result;
        setJob(data);
        console.log(data);

        const key = data?.long_description
          ?.split("~")
          .map((item) => item.trim())
          .filter((item) => item.length > 0);
        const tech = data?.skills.split(",").map((item) => item.trim());
        setTecReq(tech);

        setKeyResponsibilitiese(key);
        setFlag(false);
      };
      fuun();
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error('Something Went Wrong')
    }
  }, []);

  const [isOpen, setIsOpen] = useState({
    state: false,
  });
  const closeModal = () => setIsOpen(() => ({  state: false }));

 

  return (
    <div>
      {flag ? (
        <div className="Loading-aa">
          <h3>Loading...</h3>
        </div>
      ) : (
        <div className="about-us-main careerDetails-a">
          <div className="container">
            <h2 className="job-main">{job.job_title}</h2>
            <div className="row border defolt box-career">
              <div className="col-12 col-sm-6 col-md-6 col-lg-3">
                <span className="careers-tag">Job ID/Reference Code</span>
                <h5>{job.job_id}</h5>
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-3">
                <span className="careers-tag">Work Experience</span>
                <h5>{job.job_requirement}</h5>
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-3">
                <span className="careers-tag">Education</span>
                <h5>{job.education_level}</h5>
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-3">
                <span className="careers-tag">Location</span>
                <h5>{job.location}</h5>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-12 col-sm-8 col-md-8 col-lg-8">
                <div className="privacy-policy-text1 keyRes">
                  <h6>Key Responsibilities</h6>
                  <ul className="CCCC">
                    {keyResponsibilities?.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="col-12 col-sm-4 col-md-4 col-lg-4">
                <div className="privacy-policy-text1 techReq">
                  <h6>Technical Requirements:</h6>

                  <ul className="CCCC">
                    {techReq?.map((item, index) => (
                      <li>{item}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="apply-now">
              <button
                className="apply-now-bt"
                onClick={() => {
                  setIsOpen({
                    state: true,
                  });
                }}
              >
                Apply Now
              </button>
            </div>
          </div>
        </div>
      )}
      <ApplyModal title={job.job_title} isOpen={isOpen} closeModal={closeModal}/>
    </div>
  );
};

export default CareerDetails;
